import React from "react";
import { Button, Modal } from "react-bootstrap";

export default class AlertModal extends React.Component {

  render() {
    // const { bodyText } = this.props;
    return (
      <Modal show={this.props.show} onHide={this.props.handleClose} centered>
        <Modal.Header
          className="text-center"
          style={{ backgroundColor: "red" }}
          closeButton
          onHide={this.props.handleCancel}
        >
          <Modal.Title className="w-100">
            <h1>{this.props.modalHeading}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.modalBody}</Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary"
            onClick={this.props.handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-danger"
            onClick={this.props.confirmBtnClick}
          >
            {this.props.confirmBtnText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
