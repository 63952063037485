import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import {useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import {Fetch} from "../../helpers/Fetch";

export default function ModalSwitchCollector(props) {
    //Close the modal using a function from the calling page.
    const handleCloseModal = () => {
        props.handle_close_modal();
    };

    const handleChange = (e) => {
        // console.log('handleChange', e)
        // console.log('handleChange ModalSwitchData', props)
        //
        // console.log('handleChange new parent CID', e.target.value)  //New parent CID
        // console.log('handleChange selected existing parent CID', e.target.dataset.child_cid)  //Child CID to change

        Fetch("devices_api.php", {
            action: "change_parent_collector_id",
            collector_id: e.target.dataset.child_cid, //Child collector to change parent
            new_parent_collector_id: e.target.value //New parent_collector_id
        })
            .then((data) => {
                if (data && data.status === "ok") {
                    handleCloseModal()
                } else {
                    console.error("error", data);
                }
            })
            .catch((err) => console.error(err));
    }

    return (
        <Modal
            show={props.ModalSwitchData.show_modal} //this can be true because we are checking for data above
            backdrop="static" //User must press cancel or submit to hide modal
            keyboard={false} //User must press cancel or submit to hide modal
            centered //centered on screen vertically
            size="sm" //Modal size
        >
            <Modal.Header className="text-center">
                <Modal.Title>
                    Select Parent Collector<br/>
                    {props.ModalSwitchData.collector_name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <h5 className="text-center text-danger"><b>Making a selection<br/>WILL cause an<br/>IMMEDIATE<br/>CHANGE/UPDATE!</b></h5>
                    {props.ModalSwitchData.avail_site_collectors.map((data) =>
                        <div className="form-check ms-4" key={'x_' + data.collector_id}>
                            <label key={'y_' + data.collector_id}>
                                <input
                                    type="radio"
                                    name="col_select"
                                    value={data.collector_id}  //selectable new parent_collector_id
                                    onChange={handleChange}
                                    //Default checked when the parent selection = the existing parent
                                    // checked={data.collector_id == parent_collector_id}
                                    checked={data.collector_id === Number(props.ModalSwitchData.parent_collector_id)}
                                    className="form-check-input"
                                    data-child_cid={props.ModalSwitchData.collector_id}
                                />
                                {data.collector_name}
                            </label>
                        </div>
                    )}

                    <hr/>
                    <div className="float-end">
                        <Button className="btn-sm btn-secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
