import React, { useState, useEffect, useCallback } from "react";
import { Fetch } from "../../helpers/Fetch";
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Tooltip,
} from "recharts";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import BadReadingsTable from "./BadReadingsTable";

dayjs.extend(utc);
dayjs.extend(timezone);

const BadDataGraph = ({
    report_bill_id,
    site_tz,
    device_id,
    detect_table_pan,
}) => {
    const [deviceId, _setDeviceId] = useState(device_id);
    const [chartLoaded, _setChartLoaded] = useState(!!report_bill_id);
    const [loading, setLoading] = useState(false);
    const [initialDate, setInitialDate] = useState(
        dayjs().tz(site_tz).subtract(1, "day")
    );

    const [endDate, setEndDate] = useState(dayjs().tz(site_tz));
    const [graphDifference, setGraphDifference] = useState("1");
    const [plotData, setPlotData] = useState([]);
    const [yAxisDomain, setYAxisDomain] = useState([0, 0]);
    const [_xTickCount, setXTickCount] = useState(4);
    const [yTickCount, setYTickCount] = useState(0);
    const transformArray = useCallback(
        (data) => {
            const currentDate = dayjs().tz(site_tz).format("YYYY-MM-DD");
            const currentHour = dayjs().tz(site_tz).format("HH:mm:ss");

            return data.map((item) => {
                const [date, hour] = item.x.split("T");

                return item.kwd === 0 &&
                    (date > currentDate ||
                        (date === currentDate && hour >= currentHour))
                    ? { ...item, kwd: null }
                    : item;
            });
        },
        [site_tz]
    );

    const getTrendData = useCallback(
        async (fromDate, toDate) => {
            setLoading(true);
            try {
                const fdata = await Fetch("bad_data.php", {
                    action: "get_trend_data",
                    report_bill_id: report_bill_id,
                    fromDate: fromDate.utc().format("MM-DD-YYYY HH:mm"),
                    toDate: toDate.utc().format("MM-DD-YYYY HH:mm"),
                });
                if (fdata && fdata.status === "ok") {
                    const data = transformArray(fdata.data);

                    const maxKwd = Math.max(...data.map((o) => o.kwd));
                    const [rangeYDomain, tickCount] =
                        calculateYAxisDomain(maxKwd);

                    setPlotData(data);
                    setYAxisDomain([0, rangeYDomain]);
                    setYTickCount(tickCount);
                    setXTickCount(4);
                }
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setLoading(false);
            }
        },
        [transformArray, report_bill_id]
    );
    useEffect(() => {
        getTrendData(initialDate, endDate);
        //eslint-disable-next-line
    }, [initialDate, endDate]);

    const handleDateChange = (e) => {
        const newDate = dayjs(e.target.value).tz(site_tz, true).startOf("day");
        setInitialDate(newDate);
        setEndDate(newDate.add(graphDifference, "day"));
    };

    const handleUpdateDates = async () => {
        await getTrendData(initialDate, endDate);
    };

    const calculateYAxisDomain = (maxKwd) => {
        let rangeYDomain = 0;
        let tickCount = 0;

        if (maxKwd > 10000) {
            rangeYDomain = (Math.floor(maxKwd / 10000) + 1) * 10000;
        } else if (maxKwd > 1000) {
            rangeYDomain = (Math.floor(maxKwd / 1000) + 1) * 1000;
        } else if (maxKwd > 100) {
            rangeYDomain = (Math.floor(maxKwd / 100) + 1) * 100;
            tickCount =
                rangeYDomain < 400
                    ? rangeYDomain / 50 + 1
                    : rangeYDomain / 100 + 1;
        } else if (maxKwd > 10) {
            rangeYDomain = (Math.floor(maxKwd / 10) + 1) * 10;
            tickCount =
                rangeYDomain < 40
                    ? rangeYDomain / 5 + 1
                    : rangeYDomain / 10 + 1;
        } else {
            rangeYDomain = (Math.floor(maxKwd / 1) + 1) * 1;
            tickCount =
                rangeYDomain < 4 ? rangeYDomain + 1 : rangeYDomain / 1 + 1;
        }

        return [rangeYDomain, tickCount];
    };

    const formatXAxisLine = (tickItem) => dayjs(tickItem).format("MM-DD HH:mm");

    const formatYAxis = (tickItem) => Number(tickItem).toFixed(2);

    const panNegative = () => {
        const newInitialDate = initialDate.subtract(graphDifference, "day");

        setEndDate(initialDate);
        setInitialDate(newInitialDate);
    };

    const panPositive = () => {
        const newEndDate = endDate.add(graphDifference, "day");
        const currentTime = dayjs(); // Get the current date and time using dayjs

        if (newEndDate.isAfter(currentTime)) {
            const adjustedEndDate = currentTime;
            const adjustedInitialDate = currentTime.subtract(
                graphDifference,
                "day"
            );

            setInitialDate(adjustedInitialDate);
            setEndDate(adjustedEndDate);
        } else {
            setInitialDate(endDate);
            setEndDate(newEndDate);
        }
    };

    const spanChange = (e) => {
        const newGraphDifference = e.target.value;
        setGraphDifference(newGraphDifference);

        const newInitialDate = endDate.subtract(newGraphDifference, "days");
        setInitialDate(newInitialDate);
    };

    const CustomTooltipLine = ({ payload, label }) => (
        <div className="custom-tooltip">
            <div className="custom-tooltip-header">
                <p className="tooltip-label label">
                    {dayjs(label).utcOffset(site_tz).format("MM-DD HH:mm")}
                </p>
            </div>
            <div style={{ color: "blue", float: "left" }}>&#9632;</div>
            <div className="custom-tooltip-body" style={{ float: "left" }}>
                kW Demand: {payload[0]?.value}
            </div>
        </div>
    );

    return (
        <div>
            {chartLoaded && (
                <ResponsiveContainer
                    width="100%"
                    height={400}
                    className="Tenantv2_chart mb-5"
                >
                    <LineChart
                        width="130%"
                        height={700}
                        data={plotData}
                        margin={{ top: 0, right: 20, bottom: 25, left: 5 }}
                    >
                        <Line
                            dataKey="kwd"
                            stroke="#8884d8"
                            dot={false}
                            type="linear"
                            animationDuration={0}
                            isAnimationActive={false}
                            connectNulls={true}
                        />
                        <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                        <XAxis
                            dataKey="x"
                            tickFormatter={formatXAxisLine}
                            angle={70}
                            style={{ fontSize: ".8rem" }}
                            dy={33}
                            dx={15}
                            label={{
                                value: "kW Demand",
                                dy: 80,
                                angle: 0,
                                position: "center",
                            }}
                        />
                        <YAxis
                            tickFormatter={formatYAxis}
                            type="number"
                            domain={yAxisDomain}
                            tickCount={yTickCount}
                            label={{
                                value: "kW Demand",
                                dx: -40,
                                angle: -90,
                                position: "center",
                            }}
                        />
                        <Tooltip content={<CustomTooltipLine />} />
                    </LineChart>
                </ResponsiveContainer>
            )}

            {chartLoaded ? (
                <div>
                    <div className="input-group d-sm-none d-md-block text-center">
                        <div className="offset-4 col-4">
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioSpanSelection"
                                    id="spanSelect24hours"
                                    value="1"
                                    onChange={spanChange}
                                    checked={graphDifference === "1"}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="spanSelect24hours"
                                >
                                    24 Hours
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioSpanSelection"
                                    id="spanSelect7days"
                                    value="7"
                                    onChange={spanChange}
                                    checked={graphDifference === "7"}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="spanSelect7days"
                                >
                                    7 Days
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioSpanSelection"
                                    id="spanSelect30days"
                                    value="30"
                                    onChange={spanChange}
                                    checked={graphDifference === "30"}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="spanSelect30days"
                                >
                                    30 Days
                                </label>
                            </div>
                        </div>
                        <div className="mx-auto">
                            <button
                                id="PanBackward"
                                className={` hidden-print ${
                                    loading ? "" : "btn-primary"
                                } `}
                                value="negative"
                                onClick={panNegative}
                                disabled={loading}
                            >
                                <span className="fas fa-chevron-left" />
                            </button>

                            <input
                                className="rounded text-center mx-2 "
                                id="timezone"
                                value={site_tz}
                                disabled
                            />

                            <label
                                htmlFor="fromDate"
                                className="col-form-label form-control-sm"
                            >
                                From:
                            </label>
                            <input
                                className="rounded text-center"
                                type="datetime-local"
                                id="fromDate"
                                step={1}
                                value={dayjs(initialDate)
                                    .startOf("day")
                                    .format("YYYY-MM-DD HH:mm")}
                                onChange={handleDateChange}
                            />

                            <label
                                htmlFor="toDate"
                                className="col-form-label form-control-sm"
                            >
                                To:
                            </label>
                            <input
                                className="rounded text-center mx-2 "
                                id="toDate"
                                type="datetime-local"
                                value={dayjs(endDate)
                                    .startOf("day")
                                    .format("YYYY-MM-DD HH:mm")}
                                disabled
                            />

                            <button
                                id="PanForward"
                                className={`${
                                    loading ? "" : "btn-primary"
                                }  hidden-print`}
                                value="positive"
                                disabled={loading}
                                onClick={panPositive}
                            >
                                <span className="fas fa-chevron-right" />
                            </button>

                            <button
                                className="btn btn-primary btn-sm mb-1 mx-2"
                                onClick={handleUpdateDates}
                            >
                                Update
                            </button>
                        </div>
                    </div>

                    {loading && (
                        <div
                            className="text-center"
                            style={{ fontSize: "15px", marginTop: "10px" }}
                        >
                            <div className="loading-div">
                                Loading{" "}
                                <img
                                    alt=""
                                    height={20}
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <>
                    <div
                        className="text-center"
                        style={{ height: "200px", fontSize: "20px" }}
                    >
                        <div className="loading-div">
                            Loading{" "}
                            <img
                                alt=""
                                height={20}
                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                            />
                        </div>
                    </div>
                </>
            )}
            <BadReadingsTable
                device_id={deviceId}
                site_tz={site_tz}
                report_bill_id={report_bill_id}
                detect_table_pan={detect_table_pan}
                initial_date={initialDate}
                endDate={endDate}
            />
        </div>
    );
};

export default BadDataGraph;
