/**
 * React Core Exports
 */
import React, {useEffect, useState} from "react";

/**
 * Components That Complement Reader.js
 */
import Navigation from "../Navbar";
import AppFooter from "../AppFooter";
import ReadingsTable from "./ReadingsTable";

/**
 * Internal Helpers
 */
import {Fetch} from "../../helpers/Fetch";
import SiteSelect from "../Global/Site_select2";
import {dataURLtoFile} from "../../helpers/dataURLtoFile";
import {svr_name} from "../../helpers/AppConstants";

/**
 * Exported External Libraries
 */
import Select from "react-select";
import {isMobile} from "react-device-detect";

function Reader(props) {
    console.log("****Reader render****", props);
    const [group_data, set_group_data] = useState([]);

    const [site_id, set_site_id] = useState(
        sessionStorage.getItem("prev_site_id")
    );
    const [report_group_id, set_report_group_id] = useState(
        sessionStorage.getItem("rgid")
    );

    const [readings_data, set_readings_data] = useState([]);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [image_count, set_image_count] = useState(0);
    const [manual_read_date, set_manual_read_date] = useState("");
    const [report_group_name, set_report_group_name] = useState("");
    const [manual_kwh_read, set_manual_kwh_read] = useState("");

    const update_storage = () => {
        set_image_count(localStorage.length - 3);
    };

    const handle_image_count_increase = () => {
        set_image_count(image_count + 1);
    };

    const handle_image_count_reduce = () => {
        if (image_count >= 0) {
            set_image_count(0);
        }
    };

    useEffect(() => {
        //Try this, begin interval when is offline end it when online status gets back

        //console.log("useEffect manual_kwh_read");
        get_all_groups(site_id);
        if (isOnline) {
            let form_data = new FormData();
            Object.keys(localStorage).forEach((key) => {
                let key_item = key.substring(0, 10);
                let key_ts = key.substring(15); //local_img_4116_2023-04-07T19:00:11.478Z

                //Get device_id : Someday device_id will be more than 4 digits
                // let x = key.substring(10);
                // let device_id = key.substring(0, key.indexOf("_"));

                if (key_item === "local_img_") {
                    // console.log("Processing Local Storage key", key_ts, key);

                    let base_64_str = localStorage.getItem(key);
                    let file = dataURLtoFile(base_64_str, "test_file");
                    form_data.append("action", "upload_file");
                    form_data.append("picture", file); //Attach image
                    form_data.append("mobile_ts", isMobile ? "true" : "false");
                    form_data.append("device_id", parseInt(key.substring(10)));
                    form_data.append("file_type", "jpeg");
                    form_data.append("exifDateTimeTaken", key_ts);

                    fetch(svr_name + "Reader_api.php", {
                        method: "POST",
                        credentials: "include",
                        mode: "cors",
                        body: form_data,
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.status === "ok") {
                                localStorage.removeItem(key);
                                get_reading_data();
                                handle_image_count_reduce();
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
        } else {  //offline
            setInterval(() => {
                if (isOnline) {
                    let form_data = new FormData();
                    Object.keys(localStorage).forEach((key) => {
                        //KEY=: local_img_4116_2023-04-07T19:00:11.478Z
                        let key_item = key.substring(0, 10);
                        let key_ts = key.substring(15);

                        //Get device_id : Someday device_id will be more than 4 digits
                        // let x = key.substring(10);
                        // let device_id = key.substring(0, key.indexOf("_"));

                        if (key_item === "local_img_") {
                            // console.log(
                            //     "Processing Local Storage key",
                            //     device_id,
                            //     key_ts,
                            //     key
                            // );

                            let base_64_str = localStorage.getItem(key);
                            let file = dataURLtoFile(base_64_str, "test_file");

                            form_data.append("action", "upload_file");
                            form_data.append("picture", file); //Attach image
                            form_data.append("mobile_ts", isMobile ? "true" : "false");
                            form_data.append("device_id", parseInt(key.substring(10)));
                            form_data.append("file_type", "jpeg");
                            // form_data.append(
                            //   "exifDateTimeTaken",
                            //   dayjs().utc().toISOString()
                            // );
                            form_data.append("exifDateTimeTaken", key_ts);

                            fetch(svr_name + "Reader_api.php", {
                                method: "POST",
                                credentials: "include",
                                mode: "cors",
                                body: form_data,
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.status === "ok") {
                                        localStorage.removeItem(key);
                                        handle_image_count_reduce();
                                        get_reading_data();
                                    }
                                })
                                .catch((error) => {
                                    console.error("Error:", error);
                                });
                        }
                    });
                }
            }, 5000);
        }
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };
        // Listen to the storage event
        window.addEventListener("storage", update_storage);

        // Listen to the online status
        window.addEventListener("online", handleStatusChange);

        // Listen to the offline status
        window.addEventListener("offline", handleStatusChange);

        // Specify how to clean up after this effect for performance improvement
        return () => {
            window.removeEventListener("online", handleStatusChange);
            window.removeEventListener("offline", handleStatusChange);
            window.removeEventListener("storage", update_storage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnline, image_count]);


    useEffect(() => {
        get_site_data();
        get_reading_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site_id, report_group_id]);
    const handleSiteChange = (e) => {
        console.log('handleSiteChange', e)
        set_site_id(e.site_id);
        get_all_groups(e.site_id);
    };

    const get_site_data = () => {
        Fetch("functions_api.php", {
            action: "pop_selectSite",
            obj_id: "selectSite",
        }).then((data) => {
            if (data.status === "ok") {
                if (data.data.length === 1) {
                    get_all_groups(data.data[0].site_id);
                }
            }
        });
    };


    const group_data_options =
        group_data &&
        group_data
            .sort(
                (a, b) =>
                    a.report_group_name &&
                    a.report_group_name.localeCompare(b.report_group_name)
            )
            .map((val) => ({
                label: val.report_group_name,
                value: val.report_group_id,
            }));

    const get_all_groups = (value) => {
        // set_site_id(value);
        Fetch(`Reader_api.php`, {
            action: "fill_groups_dropdown",
            site_id: value,
        })
            .then((data) => {
                if (data.status === "ok") {
                    if (data && data.data.length === 1) {
                        set_report_group_name(data.data[0].report_group_name);
                        set_report_group_id(data.data[0].report_group_id);
                        set_site_id(value)
                        get_reading_data(data.data[0].report_group_id, value);
                    } else {
                        set_report_group_name("");
                        set_report_group_id("");
                    }
                    set_group_data(data.data);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const handle_group_change = (e) => {
        console.log('handle_group_change', e)
        if (e.value) {
            // console.log(e.value)
            sessionStorage.setItem("rgid", e.value);
            set_report_group_name("")
            set_report_group_id(e.value);
        }
        //get_reading_data(e.value);
        get_reading_data();
    };
    const get_reading_data = (rgid, sid) => {

        console.log("rgid sid",rgid, sid)

        if (typeof rgid === 'undefined'){  //either provided or get from storage
            rgid=sessionStorage.getItem("rgid")
        }
        if (typeof sid === 'undefined'){  //either provided or get from storage
            sid=sessionStorage.getItem("sid")
        }

        console.log('get_reading_data rgid', rgid, report_group_id)
        console.log('get_reading_data sid', sid, site_id)

        //Check if all variables are defined
        if ((rgid === null) || (sid === null))
            rgid = sessionStorage.getItem("rgid");
        if ((typeof sid === 'undefined') && (typeof site_id === 'undefined'))
            return;

        //Otherwise, do fetch
        Fetch("Reader_api.php", {
            action: "get_reader_data",
            site_id: sid ? sid : site_id,
            report_group_id: rgid ? rgid : report_group_id,
        }).then((data) => {
            if (data.status === "ok") {
                set_readings_data(data.readings);
            } else {
                console.log("Fetch ERROR", data);
            }
        });
    };

    const handle_read_date_change = (e) => {
        //time is in site_tz
        set_manual_read_date(e.target.value);
    };

    const handle_kwh_input_change = (e) => {
        set_manual_kwh_read(e.target.value);
    };

    return (
        <div>
            {isOnline && <Navigation/>}
            <div>
                {/*Add all selects as top level components for easier data handling*/}
                {isOnline && (
                    <div className="col-lg-4 col-xs-12 offset-lg-4">
                        <SiteSelect handleSiteChange={handleSiteChange}/>
                        {group_data && group_data.length > 0 && (
                            <Select
                                value={{label: report_group_name, value: report_group_id}} //Set the value/text
                                options={group_data_options}
                                placeholder={"Select A Group"}
                                className="mb-2"
                                onChange={handle_group_change}
                            />
                        )}
                    </div>
                )}
                <ReadingsTable
                    readings_data={readings_data}
                    set_image_count={set_image_count}
                    isOnline={isOnline}
                    handle_image_count_increase={handle_image_count_increase}
                    image_count={image_count}
                    refresh_table={get_reading_data}
                    handle_read_date_change={handle_read_date_change}
                    manual_read_date={manual_read_date}
                    set_manual_read_date={set_manual_read_date}
                    handle_kwh_input_change={handle_kwh_input_change}
                    set_manual_kwh_read={set_manual_kwh_read}
                    manual_kwh_read={manual_kwh_read}
                />
            </div>
            {isOnline && <AppFooter/>}
        </div>
    );
}

export default Reader;
