/**
 * This is the table of meter readings for the selected report and device
 */
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { useTable } from "react-table";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { NumberFormat } from "../../helpers/NumberFormat";
import { Fetch } from "../../helpers/Fetch";

dayjs.extend(utc);
dayjs.extend(timezone);

class BadReadingsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            all_site_list: [],
            user_site_list: [],
            site_id: "",
            show_general_alert: false,
            bad_data_reads: [],
            swap_data: [],
            show_reader_table: false,
        };
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        const { device_id, detect_table_pan } = this.props;

        if (
            prevProps.detect_table_pan !== detect_table_pan ||
            prevProps.device_id !== device_id
        ) {
            this.get_reader_table_data();
        }
    }

    get_reader_table_data = () => {
        const { device_id, site_tz, endDate, initial_date } = this.props;

        if (device_id) {
            //only fetch if device_id is defined

            Fetch("bad_data.php", {
                action: "get_table_data",
                device_id: device_id,
                report_time_zone: site_tz,
                start_date: initial_date.utc().format("MM-DD-YYYY HH:mm"),
                end_date: endDate.utc().format("MM-DD-YYYY HH:mm"),
            })
                .then((data) => {
                    this.setState({
                        bad_data_reads: data.raw_data,
                        swap_data: data.swap_data,
                        show_reader_table: true,
                    });
                })
                .catch((err) => console.log(err));
        }
    };

    handleGeneralAlertClose = () => {
        this.setState({
            show_general_alert: false,
        });
    };

    handleCheckBoxClick = (e, param_id, row) => {
        Fetch("bad_data.php", {
            action: "mark_bad_reading",
            device_id: this.props.device_id,
            gmt_timestamp: row.original.gmt_timestamp,
            param_id: row.original.param_id,
            log_value_status: e.target.checked ? 1 : 0,
        }).then((_data) => {
            this.get_reader_table_data();
        });
    };

    render() {
        const columns_swap = [
            {
                Header: "ID",
                accessor: "device_swap_id",
                key: "device_swap_id",
            },
            {
                Header: "Swap UTC",
                accessor: "swap_timestamp",
                key: "swap_timestamp",
            },
            {
                Header: "Before Swap",
                accessor: "value_before_swap",
                key: "value_before_swap",
            },
            {
                Header: "After Swap",
                accessor: "value_after_swap",
                key: "value_after_swap",
            },
            {
                Header: "Swap Comment",
                accessor: "swap_comment",
                key: "swap_comment",
            },
        ];
        const columns = [
            {
                Header: "DID",
                accessor: "device_id",
                key: "device_id",
            },
            {
                Header: "Param",
                accessor: "param_id",
                key: "param_id",
            },
            {
                Header: "UTC",
                accessor: "gmt_timestamp",
                key: "gmt_timestamp",
                Cell: (props) => (
                    <div>
                        {dayjs(props.cell.value).format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                ),
            },
            {
                Header: "Site Date/Time",
                accessor: "local_time",
                key: "local_time",
            },
            {
                Header: "Reading",
                accessor: "log_value",
                key: "log_value",
                // Cell: (props) => <div>{NumberFormat().format(props.cell.value)}</div>,
                Cell: (props) => (
                    <div>
                        {props.cell.value > 0
                            ? NumberFormat().format(props.cell.value)
                            : props.cell.value}
                    </div>
                ),
            },
            {
                Header: "Diff from prev",
                accessor: "kwh_diff",
                key: "kwh_diff",
                Cell: (props) => (
                    <div>
                        {props.cell.value > 0
                            ? NumberFormat(2).format(props.cell.value)
                            : props.cell.value}
                    </div>
                ),
            },
            {
                Header: "Bad",
                accessor: "log_value_status",
                key: "log_value_status",
                Cell: ({ row }) => (
                    <span>
                        <input
                            type="checkbox"
                            defaultChecked={row.original.log_value_status}
                            onClick={(e) =>
                                this.handleCheckBoxClick(e, 10, row)
                            }
                        />
                    </span>
                ),
            },
            {
                Header: "kW",
                accessor: "kw_demand",
                key: "kw_demand",
                // Cell: (props) => <div>{NumberFormat().format(props.cell.value)}</div>,
                Cell: (props) => (
                    <div>
                        {props.cell.value > 0
                            ? NumberFormat(1).format(props.cell.value)
                            : props.cell.value}
                    </div>
                ),
            },
        ];

        function TableRenderSwap({ columns, data }) {
            const {
                getTableProps,
                getTableBodyProps,
                headerGroups,
                rows,
                prepareRow,
            } = useTable({
                columns,
                data,
            });

            return (
                <Table //Bootstrap table settings
                    striped
                    bordered
                    hover
                    responsive
                    size="sm"
                    {...getTableProps()}
                >
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr
                                key={"TableRenderSwap" + index}
                                {...headerGroup.getHeaderGroupProps()}
                            >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        key={"TableRenderSwap" + column.key}
                                        {...column.getHeaderProps()}
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <React.Fragment key={"readingTb" + i}>
                                    <tr
                                        key={"readingTb" + row.values.site_id}
                                        {...row.getRowProps()}
                                    >
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </Table>
            );
        }

        function TableRender({ columns, data }) {
            const {
                getTableProps,
                getTableBodyProps,
                headerGroups,
                rows,
                prepareRow,
            } = useTable({
                columns,
                data,
            });

            rows.sort((a, b) =>
                a.values.gmt_timestamp.localeCompare(b.values.gmt_timestamp)
            );

            return (
                <Table //Bootstrap table settings
                    striped
                    bordered
                    hover
                    responsive
                    size="sm"
                    {...getTableProps()}
                >
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={`tr-TableRender-${index}`}
                            >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        key={
                                            "th" +
                                            "-" +
                                            "TableRender" +
                                            column.key
                                        }
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <React.Fragment key={`tb-${row.id}-${i}`}>
                                    <tr
                                        className={
                                            row.values.log_value === "0"
                                                ? "bg-warning"
                                                : row.values.kwh_diff === "0"
                                                ? "bg-secondary"
                                                : ""
                                        }
                                        {...row.getRowProps()}
                                        key={`badReadingTb-${row.id}`}
                                    >
                                        {row.cells.map((cell, j) => {
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    key={`td-${i}-${j}`}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </Table>
            );
        }

        const {
            bad_data_reads,
            swap_data,
            show_reader_table,
            endDate,
            initial_date,
        } = this.state;

        return (
            <div>
                {show_reader_table && (
                    <>
                        <div
                            className="col-12 p-1"
                            style={{ border: "1px, solid black" }}
                        >
                            <div className="input-group justify-content-center">
                                <div className="form-check mt-2 ms-1">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked
                                        value=""
                                        id="hide_badCheck"
                                        onChange={() => {}}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="hide_badCheck"
                                    >
                                        Hide bad
                                    </label>
                                </div>
                                <div className="form-check form-switch mt-2 ms-1">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        checked
                                        onChange={() => {}}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="flexSwitchCheckChecked"
                                    >
                                        Mark bad
                                    </label>
                                </div>

                                <label
                                    htmlFor="fromDate"
                                    className="form-label form-control-sm mt-1"
                                >
                                    Between:
                                </label>
                                <input
                                    className="rounded text-center"
                                    type="datetime-local"
                                    id="fromDate"
                                    step={1}
                                    value={dayjs(initial_date)
                                        .startOf("day")
                                        .format("YYYY-MM-DD HH:mm")}
                                    // onChange={this.handle_date_change}
                                    onChange={() => {}}
                                />
                                <input
                                    className="rounded text-center ms-1"
                                    type="datetime-local"
                                    id="toDate"
                                    step={1}
                                    value={dayjs(endDate)
                                        .startOf("day")
                                        .format("YYYY-MM-DD HH:mm")}
                                    // onChange={this.handle_date_change}
                                    onChange={() => {}}
                                />
                                <button
                                // onClick={}
                                >
                                    Now
                                </button>
                                <label
                                    htmlFor="swapDate"
                                    className="form-label form-control-sm mt-1"
                                >
                                    Create Swap:
                                </label>
                                <input
                                    className="rounded text-center"
                                    type="datetime-local"
                                    id="swapDate"
                                    step={1}
                                    value={dayjs(initial_date)
                                        .startOf("day")
                                        .format("YYYY-MM-DD HH:mm")}
                                    // onChange={this.handle_date_change}
                                    onChange={() => {}}
                                />
                                <button
                                // onClick={}
                                >
                                    Now
                                </button>
                            </div>
                        </div>
                        <div className="mt-1">
                            {swap_data && swap_data.length > 0 && (
                                <TableRenderSwap
                                    columns={columns_swap}
                                    data={swap_data}
                                />
                            )}
                        </div>
                        <div className="mt-1">
                            {bad_data_reads && bad_data_reads.length > 0 && (
                                <TableRender
                                    columns={columns}
                                    data={bad_data_reads}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default BadReadingsTable;
