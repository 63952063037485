export const tenant_dates = [
    { name: "January 1st", value: 1 },
    { name: "February 1st", value: 2 },
    { name: "March 1st", value: 3 },
    { name: "April 1st", value: 4 },
    { name: "May 1st", value: 5 },
    { name: "June 1st", value: 6 },
    { name: "July 1st", value: 7 },
    { name: "August 1st", value: 8 },
    { name: "September 1st", value: 9 },
    { name: "October 1st", value: 10 },
    { name: "November 1st", value: 11 },
    { name: "December 1st", value: 12 },
];

export const generateYears = (back) => {
    const year = new Date().getFullYear() + 5;
    return Array.from({ length: back }, (v, i) => year - back + i + 1);
};

export const generateNextYears = (next) => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: next }, (v, i) => currentYear + i);
};
