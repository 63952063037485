import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useTable, useExpanded } from "react-table";
import { Button, Table, Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import Fuse from "fuse.js";
import "./Tenants.css";
import { NumberFormat } from "../../helpers/NumberFormat";
import { CurrencyFormat } from "../../helpers/CurrencyFormat";
import ExpandedReportTable from "./ExpandedReportTable";
import { TenantModal } from "./TenantModal";
import { Fetch } from "../../helpers/Fetch";
/******************************************
 * Dayjs imports must be at the bottom of imports
 * because the .extend statements must come after all the imports.
 ******************************************/
import dayjs from "dayjs";

//import timezone from "dayjs/plugin/timezone";
//import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

//dayjs.extend(timezone);
//dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

/********************************************
 * END OF DAYJS IMPORTS
 ********************************************/

/**
 * This is a Functional Component, not a Class Component
 * https://www.twilio.com/blog/react-choose-functional-components
 * @param props
 * @returns {JSX.Element}
 */
export default function TenantReportReactTable(props) {
    /**
     * Performing "state" this way reduces renders caused by multiple
     * setState operations to individual useState
     * https://stackoverflow.com/q/59813926/6483263
     *
     * This may go away with v18 of React: https://github.com/reactwg/react-18/discussions/21
     * Batching setStates to reduce renders
     */
    const [state, setState] = useState({
        reports: [], //collectors array to display in table
        site_id: props.site_id, //the selected site
        refresh: false,
        filter_text: "",
        report_dates: [],
        category_rows: [],
        show_dollars: true,
        show_vacant: false,
        show_house_loads: false,
        show_graph_modal: false,
        report_row: {},
        show_zero_consumption: false,
        load_value: "",
        login_data: JSON.parse(localStorage.getItem("login_data")),
        show_info_modal: false,
        group_info: {},
    });

    /**
     * This function sets the "state"
     * @param newValues - This is an object {param1: 'ddd', param2: 'cccc'}
     * https://stackoverflow.com/a/65609317/6483263
     *
     * You can update individual state property's but each change will cause a render
     * Doing it this way only causes one render by batching the changes
     */
    const thisSetState = function (newValues) {
        setState((prevValues) => {
            return { ...prevValues, ...newValues };
        });
    };

    /**
     * Runs after mounting/rendering
     * and after a change of props.site_id or state.refresh
     */
    useEffect(() => {
        thisSetState({
            reports: props.table_data,
            report_dates: props.report_dates,
            category_rows: props.category_rows,
        });
    }, [
        props.report_dates,
        props.table_data,
        state.refresh,
        props.category_rows,
    ]);

    const fuse = new Fuse(state.reports, {
        keys: ["report_name", "org_name"],
        threshold: 0,
        ignoreLocation: true,
        isCaseSensitive: false, //false is the default but I want this comment as a reminder
    });

    const search_results = fuse.search(state.filter_text);

    const handle_filter_change = (e) => {
        thisSetState({ filter_text: e.target.value });
    };

    const memoReports = useMemo(
        () =>
            state.filter_text
                ? search_results.map((result) => result.item)
                : state.reports,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state.filter_text, state.reports]
    );

    const handle_clear_filter = () => {
        thisSetState({ filter_text: "" });
        document.getElementById("device_filter_input").value = "";
    };

    const excelExport = (e) => {
        e.preventDefault();
        import(
            /* webpackChunkName: "ExcelExportNonElectric2" */ "../../helpers/ExcelExport"
        ).then((module) => {
            // console.log("ExcelExport chunk import done");
            const { report_dates, show_dollars } = state;

            let output = [
                [
                    "Space Name",
                    "Tenant Name",

                    report_dates !== undefined
                        ? report_dates[0]
                            ? dayjs(report_dates[0].report_from_date).format(
                                  "MM-DD-YY"
                              ) +
                              " - " +
                              dayjs(report_dates[0].report_to_date).format(
                                  "MM-DD-YY"
                              )
                            : ""
                        : "",
                    report_dates !== undefined
                        ? report_dates[1]
                            ? dayjs(report_dates[1].report_from_date).format(
                                  "MM-DD-YY"
                              ) +
                              " - " +
                              dayjs(report_dates[1].report_to_date).format(
                                  "MM-DD-YY"
                              )
                            : ""
                        : "",
                    report_dates !== undefined
                        ? report_dates[2]
                            ? dayjs(report_dates[2].report_from_date).format(
                                  "MM-DD-YY"
                              ) +
                              " - " +
                              dayjs(report_dates[2].report_to_date).format(
                                  "MM-DD-YY"
                              )
                            : ""
                        : "",
                    report_dates !== undefined
                        ? report_dates[3]
                            ? dayjs(report_dates[3].report_from_date).format(
                                  "MM-DD-YY"
                              ) +
                              " - " +
                              dayjs(report_dates[3].report_to_date).format(
                                  "MM-DD-YY"
                              )
                            : ""
                        : "",
                    report_dates !== undefined
                        ? report_dates[4]
                            ? dayjs(report_dates[4].report_from_date).format(
                                  "MM-DD-YY"
                              ) +
                              " - " +
                              dayjs(report_dates[4].report_to_date).format(
                                  "MM-DD-YY"
                              )
                            : ""
                        : "",
                    report_dates !== undefined
                        ? report_dates[5]
                            ? dayjs(report_dates[5].report_from_date).format(
                                  "MM-DD-YY"
                              ) +
                              " - " +
                              dayjs(report_dates[5].report_to_date).format(
                                  "MM-DD-YY"
                              )
                            : ""
                        : "",
                ],
            ];
            // let UnitConvert; //1 HCF = 748 gallons   12000 BTU = 1 ton
            memoReports.forEach((r, _i) => {
                if (!r.report_name) {
                    // Skip adding to output if report_name is null or undefined
                    return;
                }
                let row = [
                    r.report_name,
                    r.org_name,
                    show_dollars
                        ? `$${r.m1 ? parseFloat(r.m1).toFixed(2) : "0"}`
                        : `${
                              r.m1_kwh ? parseFloat(r.m1_kwh).toFixed(2) : "0"
                          } kWh - ${
                              r.m1_kw ? parseFloat(r.m1_kw).toFixed(2) : "0"
                          }KW`,
                    show_dollars
                        ? `$${r.m2 ? parseFloat(r.m2).toFixed(2) : "0"}`
                        : `${
                              r.m2_kwh ? parseFloat(r.m2_kwh).toFixed(2) : "0"
                          } kWh - ${
                              r.m2_kw ? parseFloat(r.m2_kw).toFixed(2) : "0"
                          }KW`,
                    show_dollars
                        ? `$${r.m6 ? parseFloat(r.m3).toFixed(2) : "0"}`
                        : `${
                              r.m3_kwh ? parseFloat(r.m3_kwh).toFixed(2) : "0"
                          } kWh - ${
                              r.m3_kw ? parseFloat(r.m3_kw).toFixed(2) : "0"
                          }KW`,
                    show_dollars
                        ? `$${r.m6 ? parseFloat(r.m4).toFixed(2) : "0"}`
                        : `${
                              r.m4_kwh ? parseFloat(r.m4_kwh).toFixed(2) : "0"
                          } kWh - ${
                              r.m4_kw ? parseFloat(r.m4_kw).toFixed(2) : "0"
                          }KW`,
                    show_dollars
                        ? `$${r.m5 ? parseFloat(r.m5).toFixed(2) : "0"}`
                        : `${
                              r.m5_kwh ? parseFloat(r.m5_kwh).toFixed(2) : "0"
                          } kWh - ${
                              r.m5_kw ? parseFloat(r.m5_kw).toFixed(2) : "0"
                          }KW`,
                    show_dollars
                        ? `$${r.m6 ? parseFloat(r.m6).toFixed(2) : "0"}`
                        : `${
                              r.m6_kwh ? parseFloat(r.m6_kwh).toFixed(2) : "0"
                          } kWh - ${
                              r.m6_kw ? parseFloat(r.m6_kw).toFixed(2) : "0"
                          }KW`,
                ];
                output.push(row);
            });
            console.log("output--------------------", output);

            // console.log("output", output);
            //Build this object to pass to excelExport
            const Excel = {
                template: "TenantV3.xlsx", //Excel template
                Worksheets: [
                    {
                        worksheet: "Sheet1", //Worksheet in template
                        start_row: 3, //Starting at 1
                        start_col_num: 1, //Starting at A=1, B=2
                        rows: output,
                    },
                ],
                output_filename: "TenantV3.xlsx", //Default filename of file
            };
            //console.log("export", Excel);
            module.excelExport(Excel);
        });
    };

    const handleDataToggleDollars = () => {
        thisSetState({ show_dollars: !state.show_dollars });
    };

    const handleDataToggleVacant = () => {
        thisSetState({ show_vacant: !state.show_vacant });
    };

    const handleOpenInfoModal = (report_group_id) => {
        thisSetState({ show_info_modal: true });
        Fetch("TenantV3_api.php", {
            action: "get_group_info",
            report_group_id: report_group_id,
        }).then((data) => {
            if (data.status === "ok") {
                thisSetState({
                    group_info: data.data,
                });
            }
        });
    };

    const handleCloseInfoModal = () => {
        thisSetState({ show_info_modal: false });
    };

    const handleDataToggleHouseLoads = (e) => {
        const value = e.target.value;
        if (value === "all") {
            thisSetState({ show_house_loads: false, load_value: "all" });
        } else if (value === "loads") {
            thisSetState({ show_house_loads: true, load_value: "loads" });
        } else if (value === "house") {
            thisSetState({ show_house_loads: true, load_value: "house" });
        }
    };

    const toggleConsumption = () => {
        thisSetState({
            show_zero_consumption: !state.show_zero_consumption,
        });
        props.toggle_consumption();
    };

    const handleShowGraphModal = (e, props, p) => {
        console.log(p);
        // LogUserAction("TV3: open bar modal")
        thisSetState({
            show_graph_modal: !state.show_graph_modal,
            report_row: props,
        });
    };

    const handleGraphModalClose = () => {
        thisSetState({
            show_graph_modal: false,
        });
    };

    const handleGroupExpand = (e, report_group_id, collapse_val) => {
        Fetch("TenantV3_api.php", {
            action: "UPDATE_rg_collapsed",
            report_group_id: report_group_id,
            collapsed: !collapse_val,
        }).then((_data) => {
            //   refresh table
            props.refresh_table();
        });
    };

    const handleGroupCollapse = (e, report_group_id, collapse_val) => {
        Fetch("TenantV3_api.php", {
            action: "UPDATE_rg_collapsed",
            report_group_id: report_group_id,
            collapsed: !collapse_val,
        }).then((_data) => {
            //   refresh table
            props.refresh_table();
        });
    };

    //Create Currency/Number format objects
    const cf = CurrencyFormat();
    const nf = NumberFormat();

    const columns = useMemo(
        () => [
            {
                id: "TableHeader", //Unique ID for header
                Header: () => {
                    //This is the main table header across the top of the table
                    return (
                        <div className="text-center d-print-none">
                            <span className="float-start mt-2">
                                {state.login_data.user_role > 6000 && (
                                    <>
                                        <input
                                            className="mx-2"
                                            type="checkbox"
                                            checked={state.show_dollars}
                                            onChange={handleDataToggleDollars}
                                        />
                                        <label className="form-check-label">
                                            Show Dollars
                                        </label>
                                    </>
                                )}

                                <input
                                    className="mx-2"
                                    type="checkbox"
                                    checked={state.show_vacant}
                                    onChange={handleDataToggleVacant}
                                />
                                <label className="form-check-label">
                                    Show Vacants
                                </label>
                                <input
                                    className="mx-2"
                                    type="checkbox"
                                    checked={state.show_zero_consumption}
                                    onChange={toggleConsumption}
                                />
                                <label className="form-check-label mx-1">
                                    Show Zero Consumption
                                </label>
                                <select
                                    onChange={handleDataToggleHouseLoads}
                                    className="mx-2"
                                    value={state.load_value}
                                >
                                    <option value="all">Show All Loads</option>
                                    <option value="loads">Show House</option>
                                </select>
                            </span>
                            <button
                                className="btn btn-primary btn-sm hidden-print button-bar-button mx-2"
                                disabled={props.disable_pagination}
                                onClick={(e) =>
                                    props.handle_change_offset(e, 6)
                                }
                            >
                                6&nbsp;
                                <span className="fas fa-chevron-left" />
                            </button>
                            <button
                                className="btn btn-primary btn-sm"
                                disabled={props.disable_pagination}
                                onClick={(e) =>
                                    props.handle_change_offset(e, 1)
                                }
                            >
                                1&nbsp;
                                <span className="fas fa-chevron-left" />
                            </button>
                            <span className="button-bar-button">Pan</span>
                            <button
                                className="btn btn-primary btn-sm mx-2"
                                onClick={(e) =>
                                    props.handle_change_offset(e, -1)
                                }
                            >
                                <span className="fas fa-chevron-right" />
                                &nbsp;1
                            </button>
                            <button
                                className="btn btn-primary btn-sm button-bar-button"
                                onClick={(e) =>
                                    props.handle_change_offset(e, -6)
                                }
                            >
                                <span className="fas fa-chevron-right" />
                                &nbsp;6
                            </button>
                            <button
                                className="btn btn-primary btn-sm button-bar-button"
                                onClick={(e) =>
                                    props.handle_change_offset(e, "current")
                                }
                            >
                                Current
                            </button>

                            <Button
                                className="btn btn-primary btn-sm button-bar-button mx-2"
                                onClick={props.refresh_table}
                            >
                                Refresh
                            </Button>
                            <input
                                type="text"
                                onChange={handle_filter_change}
                                id="device_filter_input"
                                placeholder="Filter(Space,Tenant Name)"
                                className="form-control-sm"
                            />
                            <button
                                className="btn-sm mx-3"
                                onClick={handle_clear_filter}
                            >
                                Clear
                            </button>
                            <button
                                className="btn btn-secondary btn-sm mx-1 "
                                onClick={excelExport}
                            >
                                Excel
                            </button>
                            <span className="float-end mt-2">
                                Legend:
                                <i
                                    className="fas fa-square-full"
                                    style={{
                                        color: "orange",
                                        marginLeft: "10px",
                                    }}
                                />
                                &nbsp;Send to admin
                                <i
                                    className="fas fa-square-full"
                                    style={{
                                        color: "yellow",
                                        marginLeft: "10px",
                                    }}
                                />
                                &nbsp;Vacant
                                <i
                                    className="fas fa-square-full"
                                    style={{
                                        color: "lightgray",
                                        marginLeft: "10px",
                                    }}
                                />
                                &nbsp;House Loads
                            </span>
                        </div>
                    );
                },
                columns: [
                    {
                        // Make an expander cell
                        Header: () => <div>#</div>, // No header
                        id: "expander", // It needs an ID
                        Cell: ({ row }) => {
                            // Use Cell to render an expander for each row.
                            // We use the getToggleRowExpandedProps prop-getter
                            // to build the expander.
                            // console.log("expand row", row);
                            // CAUTION: There is some react-table MAGIC that happens in this section

                            if (row.isExpanded) {
                                //if expanded
                                return (
                                    <i
                                        {...row.getToggleRowExpandedProps()}
                                        className="far fa-minus-square d-print-none"
                                        style={{ color: "red" }}
                                    />
                                );
                            } else {
                                //if NOT expanded
                                if (row.original.report_bill_id) {
                                    return (
                                        <i
                                            {...row.getToggleRowExpandedProps()}
                                            className="far fa-plus-square d-print-none"
                                            style={{ color: "green" }}
                                        />
                                    );
                                }
                            }
                        },
                    },
                    {
                        Header: () => <div>Report Group Name</div>,
                        accessor: "report_group_name",
                        Cell: (props) => {
                            return props.value;
                        },
                    },
                    {
                        Header: () => <div>Space Name</div>,
                        accessor: "report_name",
                        Cell: (props) => {
                            return (
                                <div>
                                    {props.value}

                                    {props.row.original.report_bill_id && (
                                        <i
                                            className="float-end far fa-chart-bar fa-lg table-icon-styling"
                                            onClick={(e) =>
                                                handleShowGraphModal(
                                                    e,
                                                    props.row.original,
                                                    props
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        Header: () => <div>Tenant Name</div>,
                        accessor: "org_name",
                        Cell: (props) => {
                            return <div>{props.value}</div>;
                        },
                    },
                    {
                        Header: () => (
                            <div className="text-center text-nowrap">
                                {state.report_dates !== undefined
                                    ? state.report_dates[0]
                                        ? dayjs(
                                              state.report_dates[0]
                                                  .report_from_date
                                          ).format("MM-DD-YY") +
                                          " - " +
                                          dayjs(
                                              state.report_dates[0]
                                                  .report_to_date
                                          ).format("MM-DD-YY")
                                        : ""
                                    : ""}
                            </div>
                        ),
                        accessor: "m1_kwh",
                        Cell: (props) => {
                            return (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {!state.show_dollars
                                                ? "Invoice: $" +
                                                  cf.format(
                                                      props.row.original.m1
                                                  )
                                                : nf.format(props.value) +
                                                  " kWh - " +
                                                  NumberFormat(2).format(
                                                      props.row.original.m1_kw
                                                  ) +
                                                  "kW"}
                                        </Tooltip>
                                    }
                                    color="black"
                                >
                                    <div className="text-center ">
                                        {
                                            props.value
                                                ? state.show_dollars
                                                    ? "$" +
                                                      cf.format(
                                                          props.row.original.m1
                                                      )
                                                    : nf.format(props.value) +
                                                      "kWh  @"
                                                : props.row.original.m1
                                                ? "$" +
                                                  cf.format(
                                                      props.row.original.m1
                                                  )
                                                : "" //default: just show the invoice amount if available
                                        }
                                        {!state.show_dollars
                                            ? props.row.original.m1_kw
                                                ? NumberFormat(2).format(
                                                      props.row.original.m1_kw
                                                  ) + "kW"
                                                : ""
                                            : ""}
                                    </div>
                                </OverlayTrigger>
                            );
                        },
                    },
                    {
                        Header: () => (
                            <div className="text-center text-nowrap">
                                {state.report_dates[1]
                                    ? dayjs(
                                          state.report_dates[1].report_from_date
                                      ).format("MM-DD-YY") +
                                      " - " +
                                      dayjs(
                                          state.report_dates[1].report_to_date
                                      ).format("MM-DD-YY")
                                    : ""}
                            </div>
                        ),
                        accessor: "m2_kwh",
                        Cell: (props) => {
                            return (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {!state.show_dollars
                                                ? "Invoice: $" +
                                                  cf.format(
                                                      props.row.original.m2
                                                  )
                                                : nf.format(props.value) +
                                                  " kWh - " +
                                                  NumberFormat(2).format(
                                                      props.row.original.m2_kw
                                                  ) +
                                                  "kW"}
                                        </Tooltip>
                                    }
                                    color="black"
                                >
                                    <div className="text-center">
                                        {
                                            props.value
                                                ? state.show_dollars
                                                    ? "$" +
                                                      cf.format(
                                                          props.row.original.m2
                                                      )
                                                    : nf.format(props.value) +
                                                      "kWh @"
                                                : props.row.original.m2
                                                ? "$" +
                                                  cf.format(
                                                      props.row.original.m2
                                                  )
                                                : "" //default: just show the invoice amount if available
                                        }
                                        {!state.show_dollars
                                            ? props.row.original.m2_kw
                                                ? NumberFormat(2).format(
                                                      props.row.original.m2_kw
                                                  ) + "kW"
                                                : ""
                                            : ""}
                                    </div>
                                </OverlayTrigger>
                            );
                        },
                    },
                    {
                        Header: () => (
                            <div className="text-center text-nowrap">
                                {state.report_dates[2] !== undefined
                                    ? state.report_dates[2]
                                        ? dayjs(
                                              state.report_dates[2]
                                                  .report_from_date
                                          ).format("MM-DD-YY") +
                                          " - " +
                                          dayjs(
                                              state.report_dates[2]
                                                  .report_to_date
                                          ).format("MM-DD-YY")
                                        : ""
                                    : ""}
                            </div>
                        ),
                        accessor: "m3_kwh",
                        Cell: (props) => {
                            return (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {!state.show_dollars
                                                ? "Invoice: $" +
                                                  cf.format(
                                                      props.row.original.m3
                                                  )
                                                : nf.format(props.value) +
                                                  " kWh - " +
                                                  NumberFormat(2).format(
                                                      props.row.original.m3_kw
                                                  ) +
                                                  "kW"}
                                        </Tooltip>
                                    }
                                    color="black"
                                >
                                    <div className="text-center">
                                        {
                                            props.value
                                                ? state.show_dollars
                                                    ? "$" +
                                                      cf.format(
                                                          props.row.original.m3
                                                      )
                                                    : nf.format(props.value) +
                                                      "kWh @"
                                                : props.row.original.m3
                                                ? "$" +
                                                  cf.format(
                                                      props.row.original.m3
                                                  )
                                                : "" //default: just show the invoice amount if available
                                        }
                                        {!state.show_dollars
                                            ? props.row.original.m3_kw
                                                ? NumberFormat(2).format(
                                                      props.row.original.m3_kw
                                                  ) + "kW"
                                                : ""
                                            : ""}
                                    </div>
                                </OverlayTrigger>
                            );
                        },
                    },
                    {
                        Header: () => (
                            <div className="text-center text-nowrap">
                                {state.report_dates[3] !== undefined
                                    ? state.report_dates[3]
                                        ? dayjs(
                                              state.report_dates[3]
                                                  .report_from_date
                                          ).format("MM-DD-YY") +
                                          " - " +
                                          dayjs(
                                              state.report_dates[3]
                                                  .report_to_date
                                          ).format("MM-DD-YY")
                                        : ""
                                    : ""}
                            </div>
                        ),
                        accessor: "m4_kwh",
                        Cell: (props) => {
                            return (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {!state.show_dollars
                                                ? "Invoice: $" +
                                                  cf.format(
                                                      props.row.original.m4
                                                  )
                                                : nf.format(props.value) +
                                                  " kWh - " +
                                                  NumberFormat(2).format(
                                                      props.row.original.m4_kw
                                                  ) +
                                                  "kW"}
                                        </Tooltip>
                                    }
                                    color="black"
                                >
                                    <div className="text-center">
                                        {
                                            props.value
                                                ? state.show_dollars
                                                    ? "$" +
                                                      cf.format(
                                                          props.row.original.m4
                                                      )
                                                    : nf.format(props.value) +
                                                      "kWh @"
                                                : props.row.original.m4
                                                ? "$" +
                                                  cf.format(
                                                      props.row.original.m4
                                                  )
                                                : "" //default: just show the invoice amount if available
                                        }
                                        {!state.show_dollars
                                            ? props.row.original.m4_kw
                                                ? NumberFormat(2).format(
                                                      props.row.original.m4_kw
                                                  ) + "kW"
                                                : ""
                                            : ""}
                                    </div>
                                </OverlayTrigger>
                            );
                        },
                    },
                    {
                        Header: () => (
                            <div className="text-center text-nowrap">
                                {state.report_dates[4] !== undefined
                                    ? state.report_dates[4]
                                        ? dayjs(
                                              state.report_dates[4]
                                                  .report_from_date
                                          ).format("MM-DD-YY") +
                                          " - " +
                                          dayjs(
                                              state.report_dates[4]
                                                  .report_to_date
                                          ).format("MM-DD-YY")
                                        : ""
                                    : ""}
                            </div>
                        ),
                        accessor: "m5_kwh",
                        Cell: (props) => {
                            return (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {!state.show_dollars
                                                ? "Invoice: $" +
                                                  cf.format(
                                                      props.row.original.m5
                                                  )
                                                : nf.format(props.value) +
                                                  " kWh - " +
                                                  NumberFormat(2).format(
                                                      props.row.original.m5_kw
                                                  ) +
                                                  "kW"}
                                        </Tooltip>
                                    }
                                    color="black"
                                >
                                    <div className="text-center">
                                        {
                                            props.value
                                                ? state.show_dollars
                                                    ? "$" +
                                                      cf.format(
                                                          props.row.original.m5
                                                      )
                                                    : nf.format(props.value) +
                                                      "kWh @"
                                                : props.row.original.m5
                                                ? "$" +
                                                  cf.format(
                                                      props.row.original.m5
                                                  )
                                                : "" //default: just show the invoice amount if available
                                        }
                                        {!state.show_dollars
                                            ? props.row.original.m5_kw
                                                ? NumberFormat(2).format(
                                                      props.row.original.m5_kw
                                                  ) + "kW"
                                                : ""
                                            : ""}
                                    </div>
                                </OverlayTrigger>
                            );
                        },
                    },
                    {
                        //column 6
                        Header: () => (
                            <div className="text-center text-nowrap">
                                {state.report_dates[5] !== undefined
                                    ? state.report_dates[5]
                                        ? dayjs(
                                              state.report_dates[5]
                                                  .report_from_date
                                          ).format("MM-DD-YY") +
                                          " - " +
                                          dayjs(
                                              state.report_dates[5]
                                                  .report_to_date
                                          ).format("MM-DD-YY")
                                        : ""
                                    : ""}
                            </div>
                        ),
                        accessor: "m6_kwh",
                        Cell: (props) => {
                            return (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {!state.show_dollars
                                                ? "Invoice: $" +
                                                  cf.format(
                                                      props.row.original.m6
                                                  )
                                                : nf.format(props.value) +
                                                  " kWh - " +
                                                  NumberFormat(2).format(
                                                      props.row.original.m6_kw
                                                  ) +
                                                  "kW"}
                                        </Tooltip>
                                    }
                                    color="black"
                                >
                                    <div className="text-center">
                                        {
                                            props.value //does m6_kwh contain a value
                                                ? state.show_dollars
                                                    ? "$" +
                                                      cf.format(
                                                          props.row.original.m6
                                                      )
                                                    : nf.format(props.value) +
                                                      "kWh @"
                                                : props.row.original.m6
                                                ? "$" +
                                                  cf.format(
                                                      props.row.original.m6
                                                  )
                                                : "" //default: just show the invoice amount if available
                                        }
                                        {!state.show_dollars
                                            ? props.row.original.m6_kw
                                                ? NumberFormat(2).format(
                                                      props.row.original.m6_kw
                                                  ) + "kW"
                                                : ""
                                            : ""}
                                    </div>
                                </OverlayTrigger>
                            );
                        },
                    },

                    {
                        Header: "report_group_id",
                        accessor: "report_group_id",
                    },
                    {
                        Header: "m1",
                        accessor: "m1",
                    },
                    {
                        accessor: "m2",
                        Header: "m2",
                    },
                    {
                        accessor: "m3",
                        Header: "m3",
                    },
                    {
                        accessor: "m4",
                        Header: "m4",
                    },
                    {
                        accessor: "m5",
                        Header: "m5",
                    },
                    {
                        accessor: "m6",
                        Header: "m6",
                    },
                    {
                        accessor: "energy_user_id",
                        Header: "energy_user_id",
                    },
                    {
                        accessor: "tree_id",
                        Header: "tree_id",
                    },
                    {
                        accessor: "dont_send_bill",
                        Header: "dont_send_bill",
                    },
                    {
                        accessor: "report_bill_id",
                        Header: "report_bill_id",
                    },
                    {
                        accessor: "collapsed",
                        Header: "collapsed",
                    },
                ],
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            state.report_dates,
            state.show_dollars,
            state.show_vacant,
            state.show_house_loads,
            state.show_graph_modal,
            props.disable_pagination,
            state.show_zero_consumption,
        ]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        // state: { expanded },
    } = useTable(
        {
            columns,
            data: memoReports,
            isLoading: true,
            autoResetExpanded: state.autoResetExpanded,
            debugTable: true,
            initialState: {
                hiddenColumns: [
                    "collapsed",
                    "report_group_name",
                    "m1",
                    "m2",
                    "m3",
                    "m4",
                    "m5",
                    "m6",
                    "report_group_id",
                    "energy_user_id",
                    "tree_id",
                    "dont_send_bill",
                    "report_bill_id",
                ],
            },
        },
        useExpanded
    );
    // let vacant = state.show_vacant ? "tenants-show-vacant" : "hide-row";
    let house_loads = state.show_house_loads
        ? "hide-row"
        : "tenants-house-load";

    console.log("RENDER TenantReportReactTable");
    // console.log(state.report_dates);
    // console.log("state.category_rows", state.category_rows);
    return (
        <>
            <Table //Bootstrap table settings
                size="sm"
                bordered
                {...getTableProps()}
                className={props.loading ? "table-loading" : ""}
            >
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={`header-${index}`}
                        >
                            {headerGroup.headers.map((column, i) => {
                                return (
                                    <th
                                        {...column.getHeaderProps()}
                                        key={`th-${index}-${i}`}
                                    >
                                        {column.render("Header")}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {/* Process collector rows */}
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <Fragment key={"idx_" + row.id}>
                                {(i === 0 //first row
                                    ? true //Produce first category row
                                    : row.values.report_group_name !==
                                      rows[i - 1].values.report_group_name) &&
                                    //If different category/true - produce category row
                                    row.values.report_group_id > 0 && (
                                        <tr
                                            {...row.getRowProps()}
                                            key={`${row.id}-${i}`}
                                        >
                                            <td
                                                colSpan={1}
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center`}
                                                key={`td-${row.id}-${i}-1`}
                                            >
                                                {row.values.collapsed ? (
                                                    <i
                                                        className="fas fa-plus-square"
                                                        onClick={(e) =>
                                                            handleGroupExpand(
                                                                e,
                                                                row.values
                                                                    .report_group_id,
                                                                row.values
                                                                    .collapsed
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <i
                                                        className="fas fa-minus-square"
                                                        onClick={(e) =>
                                                            handleGroupCollapse(
                                                                e,
                                                                row.values
                                                                    .report_group_id,
                                                                row.values
                                                                    .collapsed
                                                            )
                                                        }
                                                    />
                                                )}
                                            </td>
                                            <td
                                                colSpan={2}
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-2`}
                                            >
                                                {row.values.report_group_name}
                                                <i
                                                    className="fa fa-info-circle mx-2 d-print-none"
                                                    onClick={() =>
                                                        handleOpenInfoModal(
                                                            row.values
                                                                .report_group_id
                                                        )
                                                    }
                                                    aria-hidden="true"
                                                />
                                            </td>
                                            <td
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-3`}
                                            >
                                                {state.category_rows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id
                                                ).m1_kwh_total > 0 &&
                                                state.show_dollars
                                                    ? " $ " +
                                                      cf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m1_total
                                                      )
                                                    : state.category_rows.find(
                                                          (val) =>
                                                              val.report_group_id ===
                                                              row.values
                                                                  .report_group_id
                                                      ).m1_kwh_total > 0 &&
                                                      nf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m1_kwh_total
                                                      ) + " kWh "}
                                                {state.category_rows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id
                                                ).m1_w_gal_total > 0 &&
                                                !state.show_dollars
                                                    ? nf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m1_w_gal_total
                                                      ) + " gal"
                                                    : ""}
                                            </td>
                                            <td
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-4`}
                                            >
                                                {state.category_rows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id
                                                ).m2_kwh_total > 0 &&
                                                state.show_dollars
                                                    ? " $ " +
                                                      cf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m2_total
                                                      )
                                                    : state.category_rows.find(
                                                          (val) =>
                                                              val.report_group_id ===
                                                              row.values
                                                                  .report_group_id
                                                      ).m1_kwh_total > 0 &&
                                                      nf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m2_kwh_total
                                                      ) + " kWh "}
                                                {state.category_rows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id
                                                ).m2_w_gal_total > 0 &&
                                                !state.show_dollars
                                                    ? nf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m2_w_gal_total
                                                      ) + " gal"
                                                    : ""}
                                            </td>
                                            <td
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-5`}
                                            >
                                                {state.category_rows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id
                                                ).m3_kwh_total > 0 &&
                                                state.show_dollars
                                                    ? " $ " +
                                                      cf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m3_total
                                                      )
                                                    : state.category_rows.find(
                                                          (val) =>
                                                              val.report_group_id ===
                                                              row.values
                                                                  .report_group_id
                                                      ).m1_kwh_total > 0 &&
                                                      nf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m3_kwh_total
                                                      ) + " kWh "}
                                                {state.category_rows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id
                                                ).m3_w_gal_total > 0 &&
                                                !state.show_dollars
                                                    ? nf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m3_w_gal_total
                                                      ) + " gal"
                                                    : ""}
                                            </td>
                                            <td
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-6`}
                                            >
                                                {state.category_rows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id
                                                ).m4_kwh_total > 0 &&
                                                state.show_dollars
                                                    ? " $ " +
                                                      cf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m4_total
                                                      )
                                                    : state.category_rows.find(
                                                          (val) =>
                                                              val.report_group_id ===
                                                              row.values
                                                                  .report_group_id
                                                      ).m1_kwh_total > 0 &&
                                                      nf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m4_kwh_total
                                                      ) + " kWh "}
                                                {state.category_rows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id
                                                ).m4_w_gal_total > 0 &&
                                                !state.show_dollars
                                                    ? nf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m4_w_gal_total
                                                      ) + " gal"
                                                    : ""}
                                            </td>
                                            <td
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-7`}
                                            >
                                                {state.category_rows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id
                                                ).m5_kwh_total > 0 &&
                                                state.show_dollars
                                                    ? " $ " +
                                                      cf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m5_total
                                                      )
                                                    : state.category_rows.find(
                                                          (val) =>
                                                              val.report_group_id ===
                                                              row.values
                                                                  .report_group_id
                                                      ).m1_kwh_total > 0 &&
                                                      nf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m5_kwh_total
                                                      ) + " kWh "}
                                                {state.category_rows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id
                                                ).m5_w_gal_total > 0 &&
                                                !state.show_dollars
                                                    ? nf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m5_w_gal_total
                                                      ) + " gal"
                                                    : ""}
                                            </td>
                                            <td
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-8`}
                                            >
                                                {state.category_rows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id
                                                ).m6_kwh_total > 0 &&
                                                state.show_dollars
                                                    ? " $ " +
                                                      cf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m6_total
                                                      )
                                                    : state.category_rows.find(
                                                          (val) =>
                                                              val.report_group_id ===
                                                              row.values
                                                                  .report_group_id
                                                      ).m1_kwh_total > 0 &&
                                                      nf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m6_kwh_total
                                                      ) + " kWh "}
                                                {state.category_rows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id
                                                ).m6_w_gal_total > 0 &&
                                                !state.show_dollars
                                                    ? nf.format(
                                                          state.category_rows.find(
                                                              (val) =>
                                                                  val.report_group_id ===
                                                                  row.values
                                                                      .report_group_id
                                                          ).m6_w_gal_total
                                                      ) + " gal"
                                                    : ""}
                                            </td>
                                        </tr>
                                    )}

                                <tr
                                    className={
                                        row.values.tree_id > 1999
                                            ? house_loads
                                            : row.values.energy_user_id === null
                                            ? "tenants-send-bill" //tenants-dont-send-bill
                                            : "" //No coloring
                                    }
                                    {...row.getRowProps()}
                                    key={`${row.id}-no-${i}`}
                                >
                                    {row.cells.map((cell, j) => {
                                        return (
                                            cell.row.original.report_group_id >
                                                0 &&
                                            !cell.row.original.collapsed &&
                                            (state.show_vacant ? (
                                                cell.row.original
                                                    .energy_user_id === null &&
                                                cell.row.original.tree_id ===
                                                    1000 && (
                                                    <td
                                                        {...cell.getCellProps({
                                                            className:
                                                                cell.column
                                                                    .c_className,
                                                        })}
                                                        key={`cell-${row.id}-${i}-${j}`}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            ) : (
                                                <td
                                                    {...cell.getCellProps({
                                                        className:
                                                            cell.column
                                                                .c_className,
                                                    })}
                                                    key={`cell-no-${row.id}-${i}-${j}`}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            ))
                                        );
                                    })}
                                </tr>
                                {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                                {row.isExpanded ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length}>
                                            <ExpandedReportTable
                                                report_bill_id={
                                                    row.values.report_bill_id
                                                }
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                            </Fragment>
                        );
                    })}
                </tbody>
            </Table>

            {state.show_graph_modal && (
                <TenantModal
                    show_graph_modal={state.show_graph_modal}
                    reportRow={state.report_row}
                    handleGraphModalClose={handleGraphModalClose}
                    site_tz={sessionStorage.getItem("prev_site_tz")}
                />
            )}

            <Modal
                show={state.show_info_modal}
                onHide={handleCloseInfoModal}
                keyboard="true"
                size="xl"
            >
                <Modal.Header className="justify-content-center">
                    <Modal.Title>Group Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <fieldset disabled>
                            <legend className="text-center">
                                {state.group_info.report_group_name}
                            </legend>
                            <div className="mb-3">
                                <label
                                    htmlFor="disabledTextInput"
                                    className="form-label"
                                >
                                    Group Base Name
                                </label>
                                <input
                                    type="text"
                                    id="disabledTextInput"
                                    className="form-control"
                                    placeholder={
                                        state.group_info.report_file_base_name
                                    }
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="disabledTextInput"
                                    className="form-label"
                                >
                                    Group Notes
                                </label>
                                <input
                                    type="text"
                                    id="disabledTextInput"
                                    className="form-control"
                                    placeholder={
                                        state.group_info.report_page_notes
                                    }
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="disabledTextInput"
                                    className="form-label"
                                >
                                    Group Time Zone
                                </label>
                                <input
                                    type="text"
                                    id="disabledTextInput"
                                    className="form-control"
                                    placeholder={
                                        state.group_info.report_time_zone
                                    }
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="disabledTextInput"
                                    className="form-label"
                                >
                                    Service Address 1
                                </label>
                                <input
                                    type="text"
                                    id="disabledTextInput"
                                    className="form-control"
                                    placeholder={state.group_info.service_addr1}
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="disabledTextInput"
                                    className="form-label"
                                >
                                    Service Address 2
                                </label>
                                <input
                                    type="text"
                                    id="disabledTextInput"
                                    className="form-control"
                                    placeholder={state.group_info.service_addr2}
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="disabledTextInput"
                                    className="form-label"
                                >
                                    Service Address 3
                                </label>
                                <input
                                    type="text"
                                    id="disabledTextInput"
                                    className="form-control"
                                    placeholder={state.group_info.service_addr3}
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="disabledTextInput"
                                    className="form-label"
                                >
                                    Service City
                                </label>
                                <input
                                    type="text"
                                    id="disabledTextInput"
                                    className="form-control"
                                    placeholder={
                                        state.group_info.service_addr_city
                                    }
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="disabledTextInput"
                                    className="form-label"
                                >
                                    Service State Abbreviation
                                </label>
                                <input
                                    type="text"
                                    id="disabledTextInput"
                                    className="form-control"
                                    placeholder={
                                        state.group_info.service_addr_state
                                    }
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="disabledTextInput"
                                    className="form-label"
                                >
                                    Contract Expires
                                </label>
                                <input
                                    type="text"
                                    id="disabledTextInput"
                                    className="form-control"
                                    placeholder={
                                        state.group_info.contract_expires
                                    }
                                />
                            </div>
                        </fieldset>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        id="payment-button-cancel"
                        onClick={handleCloseInfoModal}
                    >
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
