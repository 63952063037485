import { Fetch } from "../helpers/Fetch";

/**
 * Logs user actions to database
 */

//Log user action
export function LogUserAction(message) {
  Fetch("user_log_entry_api.php", {
    action: "user_log_entry",
    message: message,
  }).then((_data) => {
  });
}
