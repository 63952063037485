/**
 * This is the device table for the report
 * It also includes the graph import
 */
import React, { Component, useState } from "react";
import { Table } from "react-bootstrap";
import { useTable } from "react-table";
import { BadDataGraph } from "./BadDataGraph";
// import {Toolbar} from "./Toolbar"
import "./Bad_Data.css";

class BadDataTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show_general_alert: false,
            profile_table_data: [],
        };
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (prevProps.profile_table_data !== this.props.profile_table_data) {
            this.setState({
                profile_table_data: this.props.profile_table_data,
            });
        }
    }
    handleGeneralAlertClose = () => {
        this.setState({
            show_general_alert: false,
        });
    };

    render() {
        // console.log('render')
        const columns = [
            {
                Header: "pid",
                accessor: "default_param_id",
                key: "default_param_id",
            },
            {
                Header: "DID",
                accessor: "device_id",
                key: "device_id",
            },
            {
                Header: "Device Name",
                accessor: "device_name",
                key: "device_name",
            },
            {
                Header: "Collector Name",
                accessor: "collector_name",
                key: "collector_name",
            },
        ];

        function TableRender({ columns, data }) {
            const [device_id, set_device_id] = useState("");
            const [default_param_id, set_default_param_id] = useState("");

            const handle_bad_data_expand = (row) => {
                //.values.device_id
                set_device_id(row.values.device_id);
                set_default_param_id(22);
            };

            const detect_table_pan = () => {
                set_device_id("");
            };

            const initialState = { hiddenColumns: ["default_param_id"] };

            const {
                getTableProps,
                getTableBodyProps,
                headerGroups,
                rows,
                prepareRow,
            } = useTable({
                columns,
                data,
                state: initialState,
            });

            rows.sort((a, b) => a.values.device_id - b.values.device_id);

            return (
                <div>
                    <Table //Bootstrap table settings
                        striped
                        bordered
                        hover
                        responsive
                        size="sm"
                        // variant="dark"
                        {...getTableProps()}
                    >
                        <thead>
                            {headerGroups.map((headerGroup, index) => (
                                <tr
                                    {...headerGroup.getHeaderGroupProps()}
                                    key={"header" + index}
                                >
                                    {headerGroup.headers.map((column, j) => {
                                        return (
                                            <th
                                                {...column.getHeaderProps()}
                                                key={
                                                    "column" +
                                                    index +
                                                    "_" +
                                                    j +
                                                    1
                                                }
                                            >
                                                {column.render("Header")}
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <React.Fragment key={"body" + i}>
                                        <tr
                                            {...row.getRowProps()}
                                            key={"row" + row.id}
                                            onClick={(_e) =>
                                                handle_bad_data_expand(row)
                                            }
                                            className={
                                                device_id ===
                                                row.values.device_id
                                                    ? "highlight_sel_row"
                                                    : ""
                                            }
                                        >
                                            {row.cells.map((cell, j) => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        key={
                                                            "rowCell" +
                                                            i +
                                                            "-" +
                                                            j
                                                        }
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </Table>
                    {report_bill_id.length && (
                        <BadDataGraph
                            site_tz={site_tz}
                            report_bill_id={report_bill_id}
                            submit_rbid={submit_rbid}
                            device_id={device_id}
                            default_param_id={default_param_id}
                            detect_table_pan={detect_table_pan}
                        />
                    )}
                </div>
            );
        }

        const { profile_table_data } = this.state;
        const { site_tz, report_bill_id, submit_rbid } = this.props;

        return (
            <div>
                <div className="mt-3">
                    {profile_table_data.length > 0 && (
                        <TableRender
                            columns={columns}
                            data={profile_table_data}
                            site_tz={site_tz}
                            report_bill_id={report_bill_id}
                            submit_rbid={submit_rbid}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default BadDataTable;
