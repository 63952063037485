import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { useTable } from "react-table";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { NumberFormat } from "../../helpers/NumberFormat";
import { Fetch } from "../../helpers/Fetch";

dayjs.extend(utc);
dayjs.extend(timezone);

const BadReadingsTable = ({ device_id, site_tz, endDate, initial_date }) => {
    const [badDataReads, setBadDataReads] = useState([]);
    const [swapData, setSwapData] = useState([]);
    const [showReaderTable, setShowReaderTable] = useState(false);

    useEffect(() => {
        getReaderTableData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device_id, initial_date, endDate]);

    const getReaderTableData = useCallback(async () => {
        if (device_id > 0) {
            try {
                const data = await Fetch("bad_data.php", {
                    action: "get_table_data",
                    device_id: device_id,
                    report_time_zone: site_tz,
                    start_date: initial_date.utc().format("MM-DD-YYYY HH:mm"),
                    end_date: endDate.utc().format("MM-DD-YYYY HH:mm"),
                });

                setBadDataReads(data.raw_data);
                setSwapData(data.swap_data);
                setShowReaderTable(true);
            } catch (error) {
                console.log("err:", error);
            }
        } else {
            setShowReaderTable(false);
        }
    }, [device_id, initial_date, endDate, site_tz]);

    const handleCheckBoxClick = useCallback(
        async (e, param_id, row) => {
            try {
                await Fetch("bad_data.php", {
                    action: "mark_bad_reading",
                    device_id: device_id,
                    gmt_timestamp: row.original.gmt_timestamp,
                    param_id: row.original.param_id,
                    log_value_status: e.target.checked ? 1 : 0,
                });

                await getReaderTableData();
            } catch (error) {
                console.log("err:", error);
            }
        },
        [device_id, getReaderTableData]
    );

    const columnsSwap = useMemo(
        () => [
            { Header: "ID", accessor: "device_swap_id", key: "device_swap_id" },
            {
                Header: "Swap UTC",
                accessor: "swap_timestamp",
                key: "swap_timestamp",
            },
            {
                Header: "Before Swap",
                accessor: "value_before_swap",
                key: "value_before_swap",
            },
            {
                Header: "After Swap",
                accessor: "value_after_swap",
                key: "value_after_swap",
            },
            {
                Header: "Swap Comment",
                accessor: "swap_comment",
                key: "swap_comment",
            },
        ],
        []
    );

    const columns = useMemo(
        () => [
            { Header: "DID", accessor: "device_id", key: "device_id" },
            { Header: "Param", accessor: "param_id", key: "param_id" },
            {
                Header: "UTC",
                accessor: "gmt_timestamp",
                key: "gmt_timestamp",
                Cell: (props) => (
                    <div>
                        {dayjs(props.cell.value).format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                ),
            },
            {
                Header: "Site Date/Time",
                accessor: "local_time",
                key: "local_time",
            },
            {
                Header: "Reading",
                accessor: "log_value",
                key: "log_value",
                Cell: (props) => (
                    <div>
                        {props.cell.value > 0
                            ? NumberFormat().format(props.cell.value)
                            : props.cell.value}
                    </div>
                ),
            },
            {
                Header: "Diff from prev",
                accessor: "kwh_diff",
                key: "kwh_diff",
                Cell: (props) => (
                    <div>
                        {props.cell.value > 0
                            ? NumberFormat(2).format(props.cell.value)
                            : props.cell.value}
                    </div>
                ),
            },
            {
                Header: "Bad",
                accessor: "log_value_status",
                key: "log_value_status",
                Cell: ({ row }) => (
                    <span>
                        <input
                            type="checkbox"
                            defaultChecked={row.original.log_value_status}
                            onClick={(e) => handleCheckBoxClick(e, 10, row)}
                        />
                    </span>
                ),
            },
            {
                Header: "kW",
                accessor: "kw_demand",
                key: "kw_demand",
                Cell: (props) => (
                    <div>
                        {props.cell.value > 0
                            ? NumberFormat(1).format(props.cell.value)
                            : props.cell.value}
                    </div>
                ),
            },
        ],
        [handleCheckBoxClick]
    );
    const TableRenderSwap = ({ columns, data }) => {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
        });

        return (
            <Table //Bootstrap table settings
                striped
                bordered
                hover
                responsive
                size="sm"
                {...getTableProps()}
            >
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr
                            key={"TableRenderSwap" + index}
                            {...headerGroup.getHeaderGroupProps()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    key={"TableRenderSwap" + column.key}
                                    {...column.getHeaderProps()}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <React.Fragment key={"readingTb" + i}>
                                <tr
                                    key={"readingTb" + row.values.site_id}
                                    {...row.getRowProps()}
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
        );
    };

    const TableRender = ({ columns, data }) => {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({ columns, data });

        rows.sort((a, b) =>
            a.values.gmt_timestamp.localeCompare(b.values.gmt_timestamp)
        );

        return (
            <Table
                striped
                bordered
                hover
                responsive
                size="sm"
                {...getTableProps()}
            >
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={`tr-${index}`}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    key={column.key}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr
                                className={
                                    row.values.log_value === "0"
                                        ? "bg-warning"
                                        : row.values.kwh_diff === "0"
                                        ? "bg-secondary"
                                        : ""
                                }
                                {...row.getRowProps()}
                                key={`row-${i}`}
                            >
                                {row.cells.map((cell, j) => (
                                    <td
                                        {...cell.getCellProps()}
                                        key={`cell-${j}`}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    };

    return (
        <div>
            {showReaderTable && (
                <>
                    <div
                        className="col-12 p-1"
                        style={{ border: "1px, solid black" }}
                    >
                        <div className="input-group justify-content-center">
                            <div className="form-check mt-2 ms-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked
                                    value=""
                                    id="hide_badCheck"
                                    onChange={() => {}}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="hide_badCheck"
                                >
                                    Hide bad
                                </label>
                            </div>
                            <div className="form-check form-switch mt-2 ms-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked
                                    onChange={() => {}}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckChecked"
                                >
                                    Mark bad
                                </label>
                            </div>

                            <label
                                htmlFor="fromDate"
                                className="form-label form-control-sm mt-1"
                            >
                                Between:
                            </label>
                            <input
                                className="rounded text-center"
                                type="datetime-local"
                                id="fromDate"
                                step={1}
                                value={dayjs(initial_date)
                                    .startOf("day")
                                    .format("YYYY-MM-DD HH:mm")}
                                // onChange={this.handle_date_change}
                                onChange={() => {}}
                            />
                            <input
                                className="rounded text-center ms-1"
                                type="datetime-local"
                                id="toDate"
                                step={1}
                                value={dayjs(endDate)
                                    .startOf("day")
                                    .format("YYYY-MM-DD HH:mm")}
                                // onChange={this.handle_date_change}
                                onChange={() => {}}
                            />
                            <button
                            // onClick={}
                            >
                                Now
                            </button>
                            <label
                                htmlFor="swapDate"
                                className="form-label form-control-sm mt-1"
                            >
                                Create Swap:
                            </label>
                            <input
                                className="rounded text-center"
                                type="datetime-local"
                                id="swapDate"
                                step={1}
                                value={dayjs(initial_date)
                                    .startOf("day")
                                    .format("YYYY-MM-DD HH:mm")}
                                // onChange={this.handle_date_change}
                                onChange={() => {}}
                            />
                            <button
                            // onClick={}
                            >
                                Now
                            </button>
                        </div>
                    </div>
                    <div className="mt-1">
                        {swapData && swapData.length > 0 && (
                            <TableRenderSwap
                                columns={columnsSwap}
                                data={swapData}
                            />
                        )}
                    </div>
                    <div className="mt-1">
                        {badDataReads && badDataReads.length > 0 && (
                            <TableRender
                                columns={columns}
                                data={badDataReads}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default BadReadingsTable;
