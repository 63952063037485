import React, {Component, Suspense} from "react";
import "./NonElec.css";
// import moment from "moment";

import numeral from "numeral";
import {OverlayTrigger, Tooltip} from "react-bootstrap";


import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


class NonElecRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // chart_loaded: false,
            show_graph_modal: false
        };
    }


    render() {
        // console.log('NonElecRow props', this.props)
        const {
            param_id,
            device_name,
            units,
            yesterday,
            date_1,
            date_2,
            date_3,
            date_4,
            date_5,
            date_6,
            total,
            last_reading,
            gmt_last_reading,
            device_notes,
            ip_addr,
            scale_factor,
            device_id,
            p1_start,
            p2_start,
            p3_start,
            p4_start,
            p5_start,
            p6_start,
            p7_start
        } = this.props;

        let yesterday_empty_td =
            parseInt(yesterday) === 0 ? "yesterday_empty_td" : "";
        // console.log("gmt_last_reading", gmt_last_reading);
        let graph_tooltip = (
            <Tooltip>
                <div align="center">
                    Last raw read:{" "}
                    {numeral(parseInt(last_reading) / scale_factor).format("0,0")}
                    <br/>
                    Last scaled read: {numeral(last_reading).format("0,0")}
                    <br/>
                    Last read: {dayjs(gmt_last_reading).format("MM-DD-YYYY")}
                    <br/>
                    {dayjs(gmt_last_reading).format("HH:mm:ss")}
                    <br/>
                    did: {device_id}
                    <br/>
                    Scale factor: {scale_factor}
                    <br/>
                    IP: {ip_addr}
                    <br/>
                    Notes: {device_notes}
                    <br/>
                </div>
            </Tooltip>

        );
        let device_name_tooltips = (
            <>
                {device_name}
                    <div align="center">
                        <i
                            className="far fa-chart-bar fa-lg table-icon-styling"
                            onClick={_e => this.props.handleShowGraphModal(device_id, param_id, device_name)}
                        />
                    </div>


            </>
        );
        let date_1_tooltip = (
            <Tooltip>
                <div align="center">
                    Begin read: {numeral(p1_start).format("0,0")}
                    <br/>
                    End read: {numeral(p2_start).format("0,0")}
                </div>
            </Tooltip>

        );
        let date_2_tooltip = (
            <Tooltip>
                <div align="center">
                    Begin read: {numeral(p2_start).format("0,0")}
                    <br/>
                    End read: {numeral(p3_start).format("0,0")}
                </div>
            </Tooltip>

        );
        let date_3_tooltip = (
            <Tooltip>
                <div align="center">
                    Begin read: {numeral(p3_start).format("0,0")}
                    <br/>
                    End read: {numeral(p4_start).format("0,0")}
                </div>
            </Tooltip>
        );
        let date_4_tooltip = (
            <Tooltip>
                <div align="center">
                    Begin read: {numeral(p4_start).format("0,0")}
                    <br/>
                    End read: {numeral(p5_start).format("0,0")}
                </div>
            </Tooltip>

        );
        let date_5_tooltip = (
            <Tooltip>
                <div align="center">
                    Begin read: {numeral(p5_start).format("0,0")}
                    <br/>
                    End read: {numeral(p6_start).format("0,0")}
                </div>
            </Tooltip>
        );
        let date_6_tooltip = (
            <Tooltip>
                <div align="center">
                    Begin read: {numeral(p6_start).format("0,0")}
                    <br/>
                    End read: {numeral(p7_start).format("0,0")}
                </div>
            </Tooltip>
        );

        let row_key = device_id + "_" + param_id;
        // console.log("row_key", row_key);
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <tr key={row_key}>
                    <OverlayTrigger placement="top" overlay={graph_tooltip}  color="black" >
                    <td>
                        {device_name_tooltips}
                     </td>
                    </OverlayTrigger>
                    <td>{param_id === 29 ? "Tons CW" : units}</td>
                    <td
                        align="right"
                        className={yesterday_empty_td}
                        style={{width: "10%"}}
                    >
                        {numeral(yesterday).format("0,0")}
                    </td>
                    <OverlayTrigger placement="top" overlay={date_1_tooltip} >
                    <td align="right" style={{width: "10%"}}>

                            {numeral(date_1).format("0,0")}

                    </td>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={date_2_tooltip} >
                    <td align="right" style={{width: "10%"}}>

                            {numeral(date_2).format("0,0")}

                    </td>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={date_3_tooltip} color="black">
                    <td align="right" style={{width: "10%"}}>

                            {numeral(date_3).format("0,0")}

                    </td>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={date_4_tooltip} >
                    <td align="right" style={{width: "10%"}}>

                            {numeral(date_4).format("0,0")}

                    </td>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={date_5_tooltip} color="black">
                    <td align="right" style={{width: "10%"}}>

                            {numeral(date_5).format("0,0")}

                    </td>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={date_6_tooltip} color="black">
                    <td align="right" style={{width: "10%"}}>

                            {numeral(date_6).format("0,0")}

                    </td>
                    </OverlayTrigger>
                    <td align="right" style={{width: "10%"}}>
                        {numeral(total).format("0,0")}
                    </td>
                </tr>
                {/*<NonElectricGraph*/}
                {/*  chart_loaded={chart_loaded}*/}
                {/*  show_graph_modal={show_graph_modal}*/}
                {/*  reportRow={device_id}*/}
                {/*  param_id={param_id}*/}
                {/*  device_name={device_name}*/}
                {/*  handleGraphModalClose={this.handleGraphModalClose}*/}
                {/*/>*/}
            </Suspense>
        );
    }
}

export default NonElecRow;
