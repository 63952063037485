import React, { Component } from "react";
import dayjs from "dayjs";
import { Fetch } from "../../helpers/Fetch";
import AddNewTenantModal from "./AddNewTenantModal";
import { LogUserAction } from "../../helpers/LogUserAction";

class ExpandedReportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report_info: {},
      occ_history: [],
      report_notes: "",
      show_metering_details: false,
      report_devices: [],
      show_add_new_modal: false,
      modal_type: "Add New Tenant",
      energy_user_id: "",
      login_data: JSON.parse(localStorage.getItem("login_data")),
      site_tz: sessionStorage.getItem("prev_site_tz"),
    };
  }

  componentDidMount() {
    this.get_dd_data();
  }

  get_dd_data = () => {
    Fetch("TenantV3_api.php", {
      action: "fill_dd_t_row",
      report_bill_id: this.props.report_bill_id,
    }).then((data) => {
      if (data.status === "ok") {
        this.setState({
          occ_history: data.occ_history,
          report_devices: data.report_devices,
          report_info: data.report_info,
          report_notes: data.report_info.report_note,
        });
      } else {
        console.log("Fetch ERROR", data);
      }
    });
  };

  handleReportNotesSubmit = () => {
    const { report_notes } = this.state;
    Fetch("TenantV3_api.php", {
      action: "update_report_note",
      report_bill_id: this.props.report_bill_id,
      report_note: report_notes,
    }).then((data) => {
      this.setState({
        report_notes: data.get.report_note,
      });
    });
  };

  handleShowMeteringDetails = () => {
    const { show_metering_details } = this.state;
    this.setState({
      show_metering_details: !show_metering_details,
    });
  };
  handleReportNotesChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    this.setState({
      [name]: value,
    });
  };

  handleShowAddModal = (modal_type, element) => {
    const { login_data } = this.state;
    console.log(login_data.user_role);
    LogUserAction("Add/Edit user report details");
    if (login_data.user_role > 6000) {
      this.setState({
        show_add_new_modal: true,
        modal_type: modal_type,
        energy_user_id: element && element.energy_user_id,
      });
    }
  };

  handleCloseAddModal = () => {
    this.setState({
      show_add_new_modal: false,
    });
  };

  render() {
    const {
      report_info,
      occ_history,
      show_metering_details,
      report_devices,
      report_notes,
      show_add_new_modal,
      modal_type,
      energy_user_id,
      login_data,
    } = this.state;
    // console.log(report_notes, report_info);
    console.log(login_data);
    return (
      <div className="container-fluid">
        <div className="d-flex justify-content-center">
          <h4>
            <button
              className="badge badge-primary mx-4"
              style={{ backgroundColor: "blue" }}
              disabled={login_data.user_role < 6000}
              onClick={(_e) => this.handleShowAddModal("Add New Tenant")}
            >
              Add New Tenant
            </button>
          </h4>
          <h3 className="space_name">
            {report_info.report_name} - Tenant History
          </h3>
        </div>
        {/* occ history details */}
        <table className="table table-bordered table-responsive table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>From Date</th>
              <th>To Date</th>
              <th>Tenant Name</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
            {occ_history.map((element, index) => (
              <tr key={index + element.energy_user_id}>
                <td>
                  <i
                    className="fas fa-edit"
                    onClick={(_e) =>
                      this.handleShowAddModal("Edit Tenant", element)
                    }
                  />
                </td>
                <td>{dayjs(element.from_date).format("MM-DD-YYYY")}</td>
                <td>
                  {dayjs(element.to_date).format("YYYY") > 2049
                    ? "No End"
                    : dayjs(element.to_date).format("MM-DD-YYYY")}
                </td>
                <td>{element.org_name}</td>
                <td>{element.email_report}</td>
                <td>{element.phone_main}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="d-flex justify-content-center">
          <span className="h4">
            rb_id:&nbsp;{this.props.report_bill_id} - Location Notes
          </span>

          <button
            className="badge badge-secondary mx-5 mb-2"
            onClick={this.handleShowMeteringDetails}
            style={{ marginTop: "auto", backgroundColor: "grey" }}
          >
            Metering Details
          </button>
        </div>
        <div className="d-flex justify-content-center">
          <textarea
            className="form-control report_note"
            value={report_notes}
            onChange={this.handleReportNotesChange}
            name="report_notes"
            rows="3"
          />
          <button
            className="update_notes_btn btn btn-primary btn-sm"
            type="button"
            onClick={this.handleReportNotesSubmit}
          >
            Update Notes
          </button>
        </div>
        {/* Metering details table */}
        {show_metering_details && (
          <table className="table table-bordered table-responsive table-sm">
            <thead>
            <tr>
              <th>collector Id</th>
              <th>device Id</th>
              <th>Enabled</th>
              <th>Alarm</th>
              <th>Utility</th>
              <th>Collector Name</th>
              <th>Device Name</th>
              <th>Device Type Name</th>
              <th>Percent</th>
              <th>Notes</th>
            </tr>
            </thead>
            <tbody>
            {report_devices.map((element, index) => (
                <tr key={index}>
                  <td>{element.collector_id}</td>
                  <td>{element.device_id}</td>
                  <td>
                    {
                      <input
                          type="checkbox"
                          style={{width: "30px", height: "30px"}}
                          defaultChecked={element.enabled}
                          readOnly
                          disabled
                      />
                    }
                  </td>
                  <td>
                    {
                      <input
                          type="checkbox"
                          style={{width: "30px", height: "30px"}}
                          defaultChecked={element.alarm_enabled}
                          readOnly
                          disabled
                      />
                    }
                  </td>
                  <td>{element.utility_type_name}</td>
                  <td>{element.collector_name}</td>
                  <td>{element.device_name}</td>
                  <td>{element.device_type_name}</td>
                  <td>{element.percent}</td>
                  <td>{element.device_notes}</td>
                </tr>
            ))}
            </tbody>
          </table>
        )}
        {show_add_new_modal && (
          <AddNewTenantModal
            modal_type={modal_type}
            energy_user_id={energy_user_id}
            show_add_new_modal={show_add_new_modal}
            handle_close_modal={this.handleCloseAddModal}
            report_bill_id={this.props.report_bill_id}
            get_dd_data={this.get_dd_data}
          />
        )}
      </div>
    );
  }
}

export default ExpandedReportTable;
