
import Navigation from "../Navbar";
import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";

import { Fetch } from "../../helpers/Fetch";
import {Alert, Button, Modal} from "react-bootstrap";

export const UserProfile = () => {
  // const [user_details, set_user_details] = useState()
  const { handleSubmit, register, reset, formState } = useForm();
  const [confirm_password_match, set_confirm_password_match] = useState(false);
  const [confirm_password_val,set_confirm_password_val] = useState("")
  const [show_password_modal, set_show_password_modal] = useState(false);
  const [password_value, set_password_value] = useState("");
  const [password_reset_success, set_password_reset_success] = useState(false);
  const [confirm_show, set_confirm_show] = useState(false);
  const [success_show, set_success_show] = useState(false);
  const [form_update_success, set_form_update_success] = useState(false);
  const [detect_form_change, set_detect_form_change] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(()=>{
    get_user_details()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    set_detect_form_change(formState.isDirty)
    // console.log(formState.isDirty)
  },[formState.isDirty])

  useEffect(()=>{
    if(password_value === confirm_password_val && password_value.length > 0){
      set_confirm_password_match(true)
    } else{
      set_confirm_password_match(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[confirm_password_val])


  const get_user_details=()=>{
    Fetch("UserProfile_api.php", {
      action: "get_user_details"
    })
        .then((data) => {
          reset(data.data)
        })
        .catch((error) => {
          console.error("Error:", error);
        });
  }


 const handlePasswordOpen = (e) => {
   e.preventDefault()
   set_show_password_modal(true)
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    set_password_value(value)
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    set_confirm_password_val(value)

  };

  const handlePasswordClose = () => {
    set_show_password_modal(false)
    set_confirm_password_match(false)
  };

  const handleResetPassword = () => {

    let lowerCase = /[a-z]/g;
    let upperCase = /[A-Z]/g;
    let numbers = /[0-9]/g;


    if (!password_value.match(lowerCase)) {
      setErrorMessage("Password should contains lowercase letters!");
    } else if (!password_value.match(upperCase)) {
      setErrorMessage("Password should contain uppercase letters!");
    } else if (!password_value.match(numbers)) {
      setErrorMessage("Password should contains numbers also!");
    } else if (password_value.length < 8) {
      setErrorMessage("Password length should be more than 10.");
    } else if(password_value !== confirm_password_val){
      setErrorMessage("Password Needs to match");
    } else{
      Fetch("UserProfile_api.php", {
        action: "reset_user_password",
        password: password_value,
      })
          .then((_data) => {
            set_password_reset_success(true);
            set_success_show(true);
            set_show_password_modal(false)
            set_confirm_password_match(false)
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    }

  };

  const onSubmit = (data) => {
    // console.log(data)
      Fetch("UserProfile_api.php", {
        action: "update_user_details",
        user_profile: data,
      })
        .then((_data) => {
          set_form_update_success(true)
        })
        .catch((error) => {
          console.error("Error:", error);
        });
  };

  return (
    <div>
      <Navigation />
      <div className="container-fluid" style={{ border: "1px solid black" }}>
        <form onSubmit={handleSubmit(onSubmit)} className="col-md-12">
          {/* form full width */}

          <div className="row">
            {success_show && password_reset_success && (
              <Alert
                variant="success"
                className="text-center"
                onClose={() => set_success_show(false)}
                dismissible
              >
                Successful Password Reset!
              </Alert>
            )}

            {form_update_success &&(
                <Alert
                    variant="success"
                    className="text-center"
                    onClose={() => set_form_update_success(false)}
                    dismissible
                >
                  Profile Successfully Updated!
                </Alert>
            )}

            <div className="offset-3 col-lg-6">
              <h5 className="text-center">User Profile</h5>

              <div className="row">
                <div className="row justify-content-center mt-2">
                  <label htmlFor="org_name" className="text-nowrap  col-md-2 col-form-label">
                    Company Name
                  </label>
                  <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        disabled
                        id="org_name"
                        name="org_name"
                        {...register("org_name")}
                    />
                  </div>
                  <label htmlFor="username" className="text-nowrap col-md-2 col-form-label">
                    Username
                  </label>
                  <div className="col-md-4">
                    <input
                        disabled
                        type="text"
                        className="form-control"
                        id="username"
                        name="username"
                        {...register("username")}
                    />
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <label htmlFor="email" className="col-md-2 col-form-label">
                    Email
                  </label>
                  <div className="col-md-4">
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        {...register("email")}
                    />
                  </div>
                  <label htmlFor="user_title" className="col-md-2 text-nowrap col-form-label">
                    User Title
                  </label>
                  <div className="col-md-4">
                    <input
                        type="user_title"
                        className="form-control"
                        id="user_title"
                        name="user_title"
                        {...register("user_title")}
                    />
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <label htmlFor="fname" className="col-md-2 col-form-label">
                    First Name
                  </label>
                  <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        id="fname"
                        name="fname"
                        {...register("fname")}
                    />
                  </div>
                  <label htmlFor="lname" className="col-md-2 text-nowrap col-form-label">
                    Last Name
                  </label>
                  <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        id="lname"
                        name="lname"
                        {...register("lname")}
                    />
                  </div>
                </div>

                <div className="row justify-content-center mt-2">
                  <label htmlFor="mail_address1" className="col-md-2 col-form-label">
                    Address 1
                  </label>
                  <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        id="mail_address1"
                        name="mail_address1"
                        {...register("mail_address1")}
                    />
                  </div>
                  <label htmlFor="mail_address2" className="col-md-2 text-nowrap col-form-label">
                    Address 2
                  </label>
                  <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        id="mail_address2"
                        name="mail_address2"
                        {...register("mail_address2")}
                    />
                  </div>
                </div>

                <div className="row justify-content-center mt-2">
                  <label htmlFor="mail_city" className="col-md-1 col-form-label">
                    City
                  </label>
                  <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        id="mail_city"
                        name="mail_city"
                        {...register("mail_city")}
                    />
                  </div>
                  <label htmlFor="mail_state" className="col-md-1 text-nowrap col-form-label">
                    State
                  </label>
                  <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        id="mail_state"
                        name="mail_state"
                        {...register("mail_state")}
                    />
                  </div>
                  <label htmlFor="mail_zip" className="col-md-1 text-nowrap col-form-label">
                    ZIP
                  </label>
                  <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        id="mail_zip"
                        name="mail_zip"
                        {...register("mail_zip")}
                    />
                  </div>
                </div>

                <div className="row justify-content-center mt-2">
                  <label htmlFor="phone_main" className="col-md-2 text-nowrap col-form-label">
                    Time Zone
                  </label>
                  <div className="col-md-4">
                    <input
                        disabled
                        type="text"
                        className="form-control"
                        id="user_tz"
                        name="user_tz"
                        {...register("user_tz")}
                    />
                  </div>
                </div>

              </div>
                <div className="row mt-3 mb-2">
                  <button
                      className="text-nowrap btn btn-secondary btn-sm col-md-2"
                      onClick={handlePasswordOpen}
                  >
                    Set Password
                  </button>
                  <button
                    type="submit"
                    disabled={!detect_form_change}
                    className="btn btn-primary btn-sm offset-9 col-md-1"
                  >
                    Submit
                  </button>
                </div>
            </div>
          </div>
        </form>
        <Modal show={show_password_modal} backdrop="static" onHide={handlePasswordClose}>

          <Modal.Header closeButton>
            <Modal.Title>
              Update Password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {confirm_show &&(
                <Alert
                    variant="info"
                    className="text-center"
                    onClose={() => set_confirm_show(false)}
                    dismissible
                >
                  Password And Confirm Password Must Match!!
                </Alert>
            )}

            <label>New Password</label>
            <input
                type="password"
                name="password"
                className="form-control"
                onChange={handlePasswordChange}
            />
            <label>Confirm Password</label>
            <input
                type="password"
                name="confirm_password"
                className="form-control"
                onChange={handleConfirmPasswordChange}
            />
            <small>{errorMessage}</small>

            <div style={{fontStyle:"italic", textDecoration:"underline"}}>
              Instructions
            </div>
            <ul>
              <li>Must be 8 Characters</li>
              <li>Must Have A Number</li>
              <li>Must Have Both Small And Capital Letters</li>
            </ul>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handlePasswordClose}>
              Cancel
            </Button>
            <Button variant="primary" disabled={!confirm_password_match} onClick={handleResetPassword}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
