import React, { useState, useEffect, useMemo } from "react";
import { Table } from "react-bootstrap";
import { useTable } from "react-table";
import BadDataGraph from "./BadDataGraph";

const MeterTable = ({ meter_data, report_time_zone, report_bill_id }) => {
    const [meterData, setMeterData] = useState(meter_data);
    const [siteTz, setSiteTz] = useState(report_time_zone);
    const [reportBillId, setReportBillId] = useState(report_bill_id);
    const [deviceId, setDeviceId] = useState(0);
    const [defaultParamId, setDefaultParamId] = useState(undefined);

    useEffect(() => {
        setMeterData(meter_data);
    }, [meter_data]);

    useEffect(() => {
        setReportBillId(report_bill_id);
    }, [report_bill_id]);

    useEffect(() => {
        setSiteTz(report_time_zone);
    }, [report_time_zone]);

    const columns = useMemo(
        () => [
            {
                Header: "pid",
                accessor: "default_param_id",
                key: "default_param_id",
            },
            {
                Header: "DID",
                accessor: "device_id",
                key: "device_id",
            },
            {
                Header: "Device Name",
                accessor: "device_name",
                key: "device_name",
            },
            {
                Header: "Collector Name",
                accessor: "collector_name",
                key: "collector_name",
            },
        ],
        []
    );

    const TableRender = ({ columns, data }) => {
        /**
         * Handles when the user selects a specific device in the table
         * @param row
         */
        const handleBadDataExpand = (row) => {
            const device_id = row.values.device_id;
            setDeviceId(device_id);
            setDefaultParamId(22); // Assuming this is fixed for now
        };

        const detectTablePan = () => {
            setDeviceId(0);
        };

        // Hide the default_param_id column
        const initialState = { hiddenColumns: ["default_param_id"] };

        // Initialize the table
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
            state: initialState,
        });

        // Sort the rows by device_id
        rows.sort((a, b) => a.values.device_id - b.values.device_id);

        return (
            <>
                {data.length > 0 && (
                    <div>
                        <Table
                            striped
                            bordered
                            hover
                            responsive
                            size="sm"
                            {...getTableProps()}
                        >
                            <thead>
                                {headerGroups.map((headerGroup, i) => (
                                    <tr
                                        {...headerGroup.getHeaderGroupProps()}
                                        key={`headerGroup-${i}`}
                                    >
                                        {headerGroup.headers.map(
                                            (column, j) => (
                                                <th
                                                    {...column.getHeaderProps()}
                                                    key={`${column.key}-${i}-${j}`}
                                                >
                                                    {column.render("Header")}
                                                </th>
                                            )
                                        )}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <React.Fragment key={`fragment-${i}`}>
                                            <tr
                                                {...row.getRowProps()}
                                                key={`${row.values.device_id}-${i}`}
                                                onClick={() =>
                                                    handleBadDataExpand(row)
                                                }
                                                className={
                                                    deviceId ===
                                                    row.values.device_id
                                                        ? "highlight_sel_row"
                                                        : ""
                                                }
                                            >
                                                {row.cells.map((cell, j) => (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        key={`cell-${i}-${j}`}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                ))}
                                            </tr>
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                        </Table>
                        <BadDataGraph
                            site_tz={siteTz}
                            report_bill_id={reportBillId}
                            device_id={deviceId}
                            default_param_id={defaultParamId}
                            detect_table_pan={detectTablePan}
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <div className="mt-3">
            <TableRender columns={columns} data={meterData} />
        </div>
    );
};

export default MeterTable;
