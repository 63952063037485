/**
 *
 * @param DecimalPlaces {integer} Number of decimal places
 * @param options {Object}  Intl.NumberFormat options object
 * @returns {Intl.NumberFormat}
 * @constructor
 *
 * Example: let x = NumberFormat().format(2323.45454) produces 2,323
 * Example: let x = NumberFormat(2).format(2323.45454) produces 2,323.45
 * Example: let x = NumberFormat(2, {
 *                               style: "currency",
 *                               currency: "USD",
 *                             }).format(2323.45454) produces $2,323.45
 *
 *    NOTE: Don't use this function for currency. Use CurrencyFormat instead.
 *
 * */

export function NumberFormat(DecimalPlaces = 0, options = {}) {
  //console.log("NumberFormat");
  /*
      if DecimalPlaces is not set, default to zero decimal places and append options to options object
      else if DecimalPlaces is set, append correct options to options object
       */

  //Check for valid inputs
  if (
    DecimalPlaces &&
    (isNaN(DecimalPlaces) || DecimalPlaces < 0 || DecimalPlaces > 20)
  ) {
    console.error(
      "ERROR: DecimalPlaces is out of range!! Defaulted to zero. Value provided:",
      DecimalPlaces
    );
    DecimalPlaces = 0;
  }

  let dp_options = {
    minimumFractionDigits: 0, //Don't show trailing zero decimals like 345.400 - 00 will not show
    maximumFractionDigits: DecimalPlaces ? DecimalPlaces : 0, //If set use value otherwise 0
  };

  //Append options to default options
  let op = null;
  if (options) op = Object.assign(options, dp_options);
  else op = dp_options;

  // console.log("dp_options", dp_options);

  return new Intl.NumberFormat(navigator.language, op);
}
