import React, {Component} from "react";
import "./Alarm.css";

//3rd Part libraries
import {OverlayTrigger, Tooltip} from "react-bootstrap";

class AlarmRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alarm_ids: [],
            checked: true
        };
        this.alarm_checkbox_ref = React.createRef();
    }

    handleCheckboxClick = (e, data) => {
        const {alarm_ids} = this.state;
        this.setState({
            alarm_ids: [...alarm_ids, data.alarm_id]
        });
        this.props.handleCheckboxChange(data);
    };

    render() {
        //console.log("*** AlarmRow render ****");

        const {alarm_row} = this.props;

        let alarm_tooltip = (
            <Tooltip>
                Collector ID: {alarm_row.collector_id} <br/> Device ID:{" "}
                {alarm_row.device_id}
                <br/> {alarm_row.ip_addr ? `IP:` + alarm_row.ip_addr : ""}
            </Tooltip>
        );

        return (
            <OverlayTrigger placement="bottom" overlay={alarm_tooltip}>
                <tr
                    className={
                        alarm_row.ackd === "None" && alarm_row.alarm_active
                            ? "alarm_active_unacked"
                            : alarm_row.alarm_active && alarm_row.ackd !== "None"
                                ? "alarm_active_acked"
                                : "alarm_unacked"
                    }
                >
                    <td className="d-none d-lg-table-cell">
                        <input
                            type="checkbox"
                            id="alarm_checkbox_id"
                            defaultChecked={false}
                            name="alarm-row-checkbox"
                            onChange={e => this.handleCheckboxClick(e, alarm_row)}
                        />
                    </td>
                    <td className="d-none d-lg-table-cell">
                        <div>{alarm_row.occured}</div>
                    </td>
                    <td className="d-none d-lg-table-cell">{alarm_row.ackd}</td>
                    <td>{alarm_row.last_contact}</td>
                    <td>{alarm_row.site_name}</td>
                    <td>
                        {alarm_row.name +
                            (alarm_row.alarm_type_id === 3 //Collector alarm
                                    ? alarm_row.meter_count > 0
                                        ? " - " + alarm_row.meter_count + " metering points - " + alarm_row.alarm_message
                                        : ' - ' + alarm_row.alarm_message
                                    : // else other type of alarm
                                    (alarm_row.report_name === null
                                        ? ""
                                        : " - " + alarm_row.report_name) + //Usually the suite designation
                                    (alarm_row.org_name === null
                                        ? ""
                                        : " - " + alarm_row.org_name) + //the tenant name
                                    (alarm_row.alarm_message === null
                                        ? ""
                                        : " - " + alarm_row.alarm_message)  //the tenant name
                            )
                        }
                    </td>
                    <td>{alarm_row.device_type}</td>
                    <td className="d-none d-lg-table-cell">{alarm_row.alarm_type}</td>
                </tr>
            </OverlayTrigger>
        );
    }
}

export default AlarmRow;
