import React, { Component } from "react";
import numeral from "numeral";

class NonElecCategory extends Component {
  render() {
    const {
      cat_name,
      yester_tot,
      date_1_tot,
      date_2_tot,
      date_3_tot,
      date_4_tot,
      date_5_tot,
      date_6_tot
      // param_id
    } = this.props;
    let gt =
      date_1_tot +
      date_2_tot +
      date_3_tot +
      date_4_tot +
      date_5_tot +
      date_6_tot;

    return (
        <tr>
          <td className="text-center cat-row " colSpan={2}>
            {cat_name}
          </td>
          <td className="text-center cat-row" colSpan={1}>
            {numeral(yester_tot).format("0,0")}
          </td>
          <td className="text-center cat-row" colSpan={1}>
            {numeral(date_1_tot).format("0,0")}
          </td>
          <td className="text-center cat-row" colSpan={1}>
            {numeral(date_2_tot).format("0,0")}
          </td>
          <td className="text-center cat-row" colSpan={1}>
            {numeral(date_3_tot).format("0,0")}
          </td>
          <td className="text-center cat-row" colSpan={1}>
            {numeral(date_4_tot).format("0,0")}
          </td>
          <td className="text-center cat-row" colSpan={1}>
            {numeral(date_5_tot).format("0,0")}
          </td>
          <td className="text-center cat-row" colSpan={1}>
            {numeral(date_6_tot).format("0,0")}
          </td>
          <td className="text-center cat-row" colSpan={1}>
            {numeral(gt).format("0,0")}
          </td>
        </tr>

    );
  }
}

export default NonElecCategory;
