import React, {Component, useState} from "react";
import {Alert, Button, Modal} from "react-bootstrap";

import {useTable, useRowSelect} from "react-table";
import {Table} from "react-bootstrap";  //Table CSS formatting
import {Fetch} from "../../helpers/Fetch";

import Select from "react-select";
import {useBlockLayout} from "react-table/src/plugin-hooks/useBlockLayout";

class AdminUserNavigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show_edit_modal: false,
            edit_data: {
                code_file_prefix: "",
                enabled: "",
                report_group_id: "",
                report_group_name: "",
                sort_order: "",
                sub_tabs: "",
                tab_id: "",
                tab_text: "",
                user_options: this.props.user_options,
                show_general_alert:false
            },
            user_tabs: [],
        };
    }

    componentDidMount() {
        this.updateUserTabs(this.props.edited_user_id);
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        // console.log("USER DETAILS", this.state.user_details)

        //If the props change because of a change of the main edited_user_id in Admin2.js,
        if (this.props.edited_user_id !== prevProps.edited_user_id) {
            this.updateUserTabs(this.props.edited_user_id)
        }
    }

    updateUserTabs() {
        // console.log('UserNav updateUserTabs', this.props.edited_user_id);

        if (this.props.edited_user_id !== null)
            Fetch(`admin_nav.php`, {
                action: "get_user_menu",
                user_id: this.props.edited_user_id,
            }).then((data) => {
                if (data.status === "ok"){
                    this.setState({
                        user_tabs: data.data,
                    });
                } else{
                   this.setState({
                       show_general_alert:true
                   })
                }
            });
    };

    handleGeneralAlertClose=()=>{
        this.setState({
            show_general_alert:false
        })
    }

    render() {
        const {user_tabs, show_general_alert} = this.state;
        const {user_options, edited_user_id} = this.props

        console.log("UserNavigation Render state", this.state);

        const columns = [
            {
                Header:  "Sort Order",
                accessor: "sort_order",
                key: "sort_order",
                width:150
            },
            {
                Header: "Code File Prefix",
                accessor: "code_file_prefix",
                key: "code_file_prefix",
                width:150
            },
            {
                Header: "Tab Id",
                accessor: "tab_id",
                key: "tab_id",
                width:150
            },
            {
                Header: "Tab Text",
                accessor: "tab_text",
                key: "tab_text",
                width:100
            },
            {
                Header: "Parent Tab Id",
                accessor: "parent_tab_id",
                key: "parent_tab_id",
                width:100
            },
        ];

        const defaultPropGetter = () => ({});
        const IndeterminateCheckbox = React.forwardRef(
            ({indeterminate, ...rest}, ref) => {
                const defaultRef = React.useRef();
                const resolvedRef = ref || defaultRef;

                React.useEffect(() => {
                    resolvedRef.current.indeterminate = indeterminate;
                }, [resolvedRef, indeterminate]);

                return (
                    <>
                        <input type="checkbox" ref={resolvedRef} {...rest} />
                    </>
                );
            }
        );

        function TableRender({
                                 columns,
                                 data,
                                 getRowProps = defaultPropGetter,
                                 user_options,
                                 edited_user_id,
                                 updateUserTabs
                             }) {

            const [showEditModal, setShowEditModal] = useState(false);
            const [showAddModal, setAddModal] = useState(false);
            const [showCopyModal, setShowCopyModal] = useState(false);
            const [enableCopyButton, setEnableCopyButton] = useState(false);
            const [SelectCopyUser, setSelectCopyUser] = useState(null);
            const [showDeleteModal, setShowDeleteModal] = useState(false);

            const [edit_data, setEditData] = useState({
                edit_data: {
                    code_file_prefix: "",
                    enabled: "",
                    report_group_id: "",
                    report_group_name: "",
                    sort_order: "",
                    sub_tabs: "",
                    tab_id: "",
                    tab_text: "",
                    parent_tab_id: "",
                },
            });

            const [copy_data, setCopyData] = useState([]);
            const [delete_data, setDeleteData] = useState([]);

            const [add_data, setAddData] = useState({
                add_data: {
                    code_file_prefix: "",
                    enabled: "",
                    report_group_id: "",
                    report_group_name: "",
                    sort_order: "",
                    sub_tabs: "",
                    tab_id: "",
                    tab_text: "",
                },
            });

            const handleCloseDeleteModal = () => setShowDeleteModal(false);

            const handleShowDeleteModal = (e, val) => {
                setShowDeleteModal(true)
                setDeleteData(val);
            };

            const handleShowCopyModal = (e, val) => {
                setShowCopyModal(true);
                setEnableCopyButton(false);
                setCopyData(val);
            };

            const handleSelectCopyUser = (e) => {
                // console.log('UserNav handleSelectCopyUser', e);

                setSelectCopyUser(e.value);
                setEnableCopyButton(true);  //Enable copy button
            }

            const handleUserSelectCopy = (copy_data) => {

                // console.log('UserNav handleUserSelectCopy', edited_user_id, SelectCopyUser, copy_data);

                //Create array of tab_id to send to server
                let tab_id_array = [];
                copy_data.forEach((r) => {
                    //console.log(r);
                    tab_id_array.push(r.tab_id);
                })
                // console.log('tab_id_array', tab_id_array)

                Fetch(`admin_nav.php`, {
                    action: "copy_user_tabs",
                    edited_user_id: edited_user_id,  //User to copy from
                    copy_to_user_id: SelectCopyUser,  //User ot copy to
                    tab_id_array: tab_id_array  //Copy these tabs
                }).then((data) => {
                    console.log(data.status, data);
                });

                //Hide modal
                setShowCopyModal(false)

            }


            const handleCloseEditModal = () => setShowEditModal(false);
            const handleShowEditModal = (e, row) => {
                setEditData(row.values);
                setShowEditModal(true);
            };

            const handleUserTabDelete = () => {
                // console.log('handleUserTabDelete', delete_data)

                Fetch("admin_nav.php", {
                    action: "delete_from_user_menu",
                    tab_id: delete_data.values.tab_id,
                    edited_user_id: edited_user_id
                }).then(data => {
                    if (data.status === "ok") {
                        console.log("tab deleted");
                        updateUserTabs(edited_user_id);
                    } else
                        console.log("Error: tab NOT deleted")


                }).catch((err) => console.log(err));

                //Close modal
                setShowDeleteModal(false)

            }

            // const handleUserSelect = (e) => {
            //     console.log("handleUserSelect e=", e);
            //     this.setState({
            //         edited_user_id: e.value,
            //         show_accordion: true,
            //     });
            // };

            const handleShowAddModal = () => setAddModal(true);
            const handleCloseAddModal = () => setAddModal(false);

            const handleEditModalChange = (e) => {
                const {name, value} = e.target;
                setEditData((values) => ({
                    ...values,
                    [name]: value,
                }));
            };

            const handleAddModalChange = (e) => {
                const {name, value} = e.target;
                setAddData((values) => ({
                    ...values,
                    [name]: value,
                }));
            };

            const handleSubmitEditData = (_e) => {
                // console.log(edit_data);
                Fetch(`admin_nav.php`, {
                    action: "update_user_menu",
                    code_file_prefix: edit_data.code_file_prefix,
                    enabled: edit_data.enabled,
                    report_group_id: edit_data.report_group_id,
                    report_group_name: edit_data.report_group_name,
                    sort_order: edit_data.sort_order,
                    sub_tabs: edit_data.sub_tabs,
                    tab_id: edit_data.tab_id,
                    tab_text: edit_data.tab_text,
                    user_id: edited_user_id
                })
                    .then((_data) => {
                        setShowEditModal(false);
                        // console.log(data.data);
                        updateUserTabs(edited_user_id);
                    })
                    .catch((error) => {
                        setShowEditModal(false);
                        console.error("Error:", error);
                    });
            };

            //Submit data for a new nav tab
            const handleSubmitAddData = (_e) => {
                // console.log(add_data);

                Fetch("admin_nav.php", {
                    action: "add_user_menu",
                    code_file_prefix: add_data.code_file_prefix,
                    sort_order: add_data.sort_order,
                    tab_id: add_data.tab_id,
                    tab_text: add_data.tab_text,
                    parent_tab_id: add_data.parent_tab_id,
                    note: add_data.note,
                    user_id: edited_user_id
                }).then((_data) => {
                    setAddModal(false);
                    updateUserTabs()
                });
            };

            const {
                getTableProps,
                getTableBodyProps,
                headerGroups,
                rows,
                prepareRow,
                selectedFlatRows,
                //state: { selectedRowIds },
            } = useTable(
                {
                    columns,
                    data,
                },
                useRowSelect,
                (hooks) => {
                    hooks.visibleColumns.push((columns) => [
                        // Let's make a column for selection
                        {
                            id: "selection",
                            // The header can use the table's getToggleAllRowsSelectedProps method
                            // to render a checkbox

                            //True but we would need to do the checkbox selection ourselves ie. the Functionality
                            //This is the top th checkbox that checks all the data in the table
                            Header: ({getToggleAllRowsSelectedProps}) => (
                                <div>
                                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                                </div>
                            ),
                            // The cell can use the individual row's getToggleRowSelectedProps method
                            // to the render a checkbox
                            //For individual rows its okay but we want to copy multiple rows
                            //This is the checkbox for individual rows
                            Cell: ({row}) => (
                                <div>
                                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                                </div>
                            ),
                           width:50
                        },
                        {
                            Header: "Action",
                            accessor: "code_file_prefix",
                            Cell: ({row})=>(
                                <div>
                                    <i
                                        className="fas fa-edit me-2"
                                        onClick={(e) => handleShowEditModal(e, row)}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit"
                                    />
                                    <i
                                        className="far fa-trash-alt"
                                        onClick={(e) => handleShowDeleteModal(e, row)}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete"
                                    />
                                </div>
                            ),
                            width:100

                        },
                        ...columns,
                    ]);
                },
                useBlockLayout
            );

            //Sort row by sort_order
            rows.sort((a, b) => (a.values.sort_order > b.values.sort_order ? 1 : -1));

            // console.log("data", data);
            return (
                <>

                    <Modal show={showAddModal} onHide={handleShowAddModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>User Add Tab</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label>Sort Order</label>
                            <input
                                type="text"
                                className="form-control"
                                value={add_data.sort_order}
                                name="sort_order"
                                onChange={handleAddModalChange}
                            />

                            <label>Code File Prefix</label>
                            <input
                                type="text"
                                className="form-control"
                                value={add_data.code_file_prefix}
                                name="code_file_prefix"
                                onChange={handleAddModalChange}
                            />

                            <label>Tab Id</label>
                            <input
                                type="text"
                                className="form-control"
                                value={add_data.tab_id}
                                name="tab_id"
                                onChange={handleAddModalChange}
                            />

                            <label>Tab Text</label>
                            <input
                                type="text"
                                className="form-control"
                                value={add_data.tab_text}
                                name="tab_text"
                                onChange={handleAddModalChange}
                            />

                            <label>Parent Tab Id</label>
                            <input
                                type="text"
                                className="form-control"
                                value={add_data.sub_tabs}
                                name="parent_tab_id"
                                onChange={handleAddModalChange}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseAddModal}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleSubmitAddData}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showEditModal} onHide={handleCloseEditModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>User Tab Edit</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label>Sort Order</label>
                            <input
                                type="text"
                                className="form-control"
                                value={edit_data.sort_order}
                                name="sort_order"
                                onChange={handleEditModalChange}
                            />

                            <label>Code File Prefix</label>
                            <input
                                type="text"
                                className="form-control"
                                value={edit_data.code_file_prefix}
                                name="code_file_prefix"
                                onChange={handleEditModalChange}
                            />

                            <label>Tab Id</label>
                            <input
                                type="text"
                                className="form-control"
                                value={edit_data.tab_id}
                                name="tab_id"
                                onChange={handleEditModalChange}
                            />

                            <label>Tab Text</label>
                            <input
                                type="text"
                                className="form-control"
                                value={edit_data.tab_text}
                                name="tab_text"
                                onChange={handleEditModalChange}
                            />

                            <label>Parent Tab Id</label>
                            <input
                                type="text"
                                className="form-control"
                                value={edit_data.sub_tabs}
                                name="parent_tab_id"
                                onChange={handleEditModalChange}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseEditModal}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleSubmitEditData}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>User Tab Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete the tab</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseDeleteModal}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={handleUserTabDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showCopyModal} onHide={() => setShowCopyModal(false)} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>User Tabs To Copy</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-3">

                                <Select
                                    options={user_options}
                                    placeholder={"Select a user"}
                                    onChange={(e) => handleSelectCopyUser(e)}
                                />

                            </div>
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Code File Prefix</th>
                                    <th>Sort Order</th>
                                    <th>Invoices</th>
                                    <th>Tab Text</th>
                                    <th>Tab ID</th>
                                    <th>Parent Tab ID</th>
                                </tr>
                                </thead>
                                <tbody>
                                {copy_data.map((val, index) => (
                                    <tr key={index}>
                                        <td>{val.code_file_prefix}</td>
                                        <td>{val.sort_order}</td>
                                        {/*<td>{val.invoices}</td>*/}
                                        <td>{val.tab_text}</td>
                                        <td>{val.tab_id}</td>
                                        <td>{val.parent_tab_id}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowCopyModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="danger" disabled={!enableCopyButton}
                                    onClick={() => handleUserSelectCopy(copy_data)}>
                                Copy
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div>
                        <button
                            className="btn btn-sm btn-primary tab-child mb-2"
                            onClick={handleShowAddModal}
                        >
                            Add Tab
                        </button>
                        {selectedFlatRows.length > 0 && (
                            <button
                                className="btn btn-sm btn-primary mx-2 mb-2"
                                onClick={(e) =>
                                    handleShowCopyModal(
                                        e,
                                        selectedFlatRows.map((d) => d.original)
                                    )
                                }
                            >
                                Copy
                            </button>
                        )}
                        <Table //Bootstrap table settings
                            bordered
                            hover
                            responsive
                            size="md"
                            {...getTableProps()}
                        >
                            <thead>
                            {headerGroups.map((headerGroup, index) => (
                                <tr key={index} {...headerGroup.getHeaderGroupProps()}>

                                    {headerGroup.headers.map((column, index) => (
                                        <th key={index} {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {rows.map((row, _i) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        key={row.original.code_file_prefix + row.original.tab_id}
                                        {...row.getRowProps(getRowProps(row))}
                                    >
                                        {row.cells.map((cell, index) => {
                                            return (
                                                <td key={index} {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                </>
            );
        }

        return (
            <>
                {show_general_alert &&
                    <Alert variant="danger" className="text-center mt-2" onClose={this.handleGeneralAlertClose}
                           dismissible>
                        Something went wrong if this unexpected contact your administrator!
                    </Alert>
                }
                <div className="d-flex align-items-center justify-content-center" >
                    <TableRender
                        columns={columns}
                        data={user_tabs}
                        user_options={user_options}
                        edited_user_id={edited_user_id}
                        updateUserTabs={this.updateUserTabs.bind(this)}
                    />
                </div>

            </>

        );
    }
}

export default AdminUserNavigation;
