import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Modal, Col, Form, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { Fetch } from "../../helpers/Fetch";
import {
    tenant_dates,
    generateYears,
    generateNextYears,
} from "../../helpers/TenantDates";

export default function AddNewTenantModal({
    show_add_new_modal,
    energy_user_id,
    report_bill_id,
    modal_type,
    handle_close_modal,
    get_dd_data,
}) {
    const [showModal, setShowModal] = useState(false);
    const [noEnd, setNoEnd] = useState(false);
    const [formReadOnly, setFormReadOnly] = useState(false);

    const { register, handleSubmit, reset, watch } = useForm();

    const getEditOcc = useCallback(async () => {
        if (energy_user_id === "") {
            return;
        }
        try {
            const data = await Fetch("tenant_info_api.php", {
                action: "GETeditOccupant",
                energy_user_id,
                report_bill_id,
            });

            if (data?.status === "ok") {
                const editData = formatTenantData(data.data);
                setFormReadOnly(data.readonly === "true");
                reset(editData);
            } else {
                console.error("Error fetching tenant info", data);
            }
        } catch (err) {
            console.error("Fetch error", err);
        }
    }, [energy_user_id, report_bill_id, reset]);

    const formatTenantData = (data) => {
        let fromDate = data.from_date;
        let toDate = data.to_date;

        const isNoEnd = dayjs(toDate).format("YYYY") > 2049;
        setNoEnd(isNoEnd);
        return {
            ...data,
            from_date: dayjs(fromDate).month() + 1,
            from_year: dayjs(fromDate).format("YYYY"),
            to_date: dayjs(toDate).month() + 1,
            no_end: dayjs(toDate).format("YYYY") > 2049,
            to_year:
                dayjs(toDate).format("YYYY") > 2049
                    ? dayjs().add(1, "year").format("YYYY")
                    : dayjs(toDate).format("YYYY"),
        };
    };

    useEffect(() => {
        setShowModal(show_add_new_modal);

        if (show_add_new_modal) {
            getEditOcc();
        }
    }, [show_add_new_modal, reset, getEditOcc]);

    const onSubmit = async (formData) => {
        const payload = {
            action:
                modal_type === "Edit Tenant"
                    ? "editOccupantSubmit"
                    : "submit_new_tenant",
            energy_user_id,
            no_end: noEnd,
            email_alert: formData.email,
            email_report: formData.email,
            title: formData.user_title,
            ...formData,
            site_tz: sessionStorage.getItem("prev_site_tz"),
            site_id: sessionStorage.getItem("prev_site_id"),
            report_bill_id,
            occ_to_month: formData.to_date,
            to_year_num: formData.to_year,
            NO_occ_from_month: formData.from_date,
            from_year_num: formData.from_year,
            cust_field1: formData.t_number, //Tenant number
            cust_field2: formData.l_number, //Lease number
        };

        try {
            const data = await Fetch("tenant_info_api.php", payload);
            if (data?.status === "ok") {
                get_dd_data();
                handle_close_modal();
            } else {
                console.error("Error on submission", data);
            }
        } catch (err) {
            console.error("Submission error", err);
        }
    };

    const generatedYearsArray = generateYears(15);
    const generatedNextYearsArray = generateNextYears(10);
    const currentMonth = dayjs().month() + 1;
    const defaultMonthValue = tenant_dates.find(
        (date) => date.value === currentMonth
    );

    return (
        <Modal
            show={showModal}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
        >
            <Modal.Header className="add-tenant-modal-header">
                <Modal.Title>
                    {" "}
                    {modal_type} {formReadOnly && "(read only)"}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                    className={formReadOnly ? "read-only-form" : ""}
                >
                    {/* From/To Dates Section */}
                    <Form.Group as={Row} className="justify-content-center">
                        <Form.Label column xs={1}>
                            From
                        </Form.Label>
                        <Col sm={2}>
                            <Form.Select
                                {...register("from_date", { required: true })}
                                defaultValue={defaultMonthValue.value}
                            >
                                {tenant_dates.map((val, index) => (
                                    <option key={index} value={val.value}>
                                        {val.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col sm={2}>
                            <Form.Select
                                {...register("from_year", { required: true })}
                                defaultValue={new Date().getFullYear()}
                            >
                                {generatedYearsArray.map((year, index) => (
                                    <option key={index} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>

                        <Form.Label column xs={1}>
                            To
                        </Form.Label>
                        <Col sm={2}>
                            <Form.Select
                                disabled={noEnd}
                                {...register("to_date")}
                                defaultValue={defaultMonthValue.value}
                            >
                                {tenant_dates.map((val, index) => (
                                    <option key={index} value={val.value}>
                                        {val.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col sm={2}>
                            <Form.Select
                                disabled={noEnd}
                                {...register("to_year")}
                                value={watch("to_year")}
                                defaultValue={new Date().getFullYear() + 1}
                            >
                                {watch("to_year") &&
                                watch("to_year") < dayjs().year()
                                    ? generatedYearsArray.map((year, index) => (
                                          <option key={index} value={year}>
                                              {year}
                                          </option>
                                      ))
                                    : generatedNextYearsArray.map(
                                          (year, index) => (
                                              <option key={index} value={year}>
                                                  {year}
                                              </option>
                                          )
                                      )}
                            </Form.Select>
                        </Col>
                        <Col sm={2}>
                            <input
                                className="mt-2 me-2"
                                type="checkbox"
                                label="No End"
                                checked={noEnd}
                                onChange={() => setNoEnd(!noEnd)}
                            />
                            <label htmlFor="no_end"> No End</label>
                        </Col>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center"
                    >
                        <Form.Label column md={6} className="mt-2 text-center">
                            Tenant Information - eu_id={energy_user_id}
                            <hr />
                        </Form.Label>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center"
                    >
                        <Form.Label column style={{ whiteSpace: "nowrap" }}>
                            Tenant Number
                        </Form.Label>
                        <Col>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("t_number")}
                            />
                        </Col>
                        <Form.Label column style={{ whiteSpace: "nowrap" }}>
                            Lease Number
                        </Form.Label>
                        <Col>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("l_number")}
                            />
                        </Col>
                        <Form.Label column>Square Feet</Form.Label>
                        <Col>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("square_ft")}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label
                            column
                            sm={2}
                            style={{ whiteSpace: "nowrap" }}
                        >
                            Tenant Name
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("org_name")}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label column sm={2}>
                            Main Email
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("email", {
                                    required: true,
                                    pattern: /^\S+@\S+$/i,
                                })}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label
                            column
                            sm={2}
                            style={{ whiteSpace: "nowrap" }}
                        >
                            First Name
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("fname")}
                            />
                        </Col>
                        <Col />
                        <Form.Label column sm={2}>
                            Last Name
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("lname")}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label
                            column
                            sm={2}
                            style={{ whiteSpace: "nowrap" }}
                        >
                            Title
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("user_title")}
                            />
                        </Col>
                        <Col />
                        <Form.Label column sm={2}>
                            Phone
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="number"
                                {...register("phone_main")}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label
                            column
                            sm={2}
                            style={{ whiteSpace: "nowrap" }}
                        >
                            Mail Address 1
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("mail_address1")}
                            />
                        </Col>
                        <Col />
                        <Form.Label
                            column
                            sm={2}
                            style={{ whiteSpace: "nowrap" }}
                        >
                            Mail Address 2
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("mail_address2")}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label
                            column
                            sm={2}
                            style={{ whiteSpace: "nowrap" }}
                        >
                            Mail City
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("mail_city")}
                            />
                        </Col>
                        <Col />
                        <Form.Label column sm={2}>
                            Mail State
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("mail_state")}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Col />
                        <Form.Label
                            column
                            sm={2}
                            style={{ whiteSpace: "nowrap" }}
                        >
                            Mail Zip
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("mail_zip")}
                            />
                        </Col>
                        <Col />
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Col sm={3}>
                            <Form.Check
                                className="mt-2"
                                inline
                                label="Send Bill To Admin"
                                type="checkbox"
                                {...register("dont_send_bill")}
                            />
                        </Col>
                    </Form.Group>
                    <div className="float-end" style={{ pointerEvents: "all" }}>
                        <Button
                            className="btn-sm btn-secondary"
                            onClick={handle_close_modal}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className="mx-2 btn-sm btn-primary"
                        >
                            Submit
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
