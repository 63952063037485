import React, {Component} from "react";
// import Select from "react-select";
import {Fetch} from "../../helpers/Fetch";

class PeriodSelect extends Component {
    constructor(props) {
        super(props);

        // console.log("PeriodSelect props", this.props);
        // console.log("sessionStorage.getItem(prev_period_label)", sessionStorage.getItem("prev_period_label"))
        this.state = {
            filterText: "",
            selectedPeriod: 0,
            period_options: [],
            dont_show: props.dont_show ? props.dont_show : []  //If provided, in must be an array of NUMBERS, not strings
        };
    }

    componentDidMount() {
        if (!!this.props.rate_id) this.getPeriodData(); //Get from server
    }

    onChange = selectedOption => {
        // console.log("change", selectedOption.target.value, selectedOption)
        this.setState({selectedPeriod: selectedOption.target.value});
        this.props.handlePeriodChange(selectedOption);
    };

    componentDidUpdate(prevProps, _prevState) {
        if ((prevProps.rate_id !== this.props.rate_id) && !!this.props.rate_id) {
            // this.setState({selectedPeriod: 0})
            this.getPeriodData()
        }
    }

    render() {
        // console.log('PeriodSelect RENDER props state', this.props, this.state)
        return (
            <div className="d-inline-flex align-items-center">
                <label htmlFor="periodSelect" className="form-label">Reporting Period</label>
                <select
                    className="form-select-lg"
                    name="periodSelect"
                    onChange={this.onChange}
                    disabled={this.state.period_options.length === 0}
                    value={this.props.selectedPeriod ? this.props.selectedPeriod : 0}
                    // value={0}
                >
                    {this.state.period_options.length === 0 ?
                        <option key={-1} value={0}>Select a site first</option>
                        : this.state.period_options
                            .sort((a, b) => (a.date_effective < b.date_effective ? 1 : -1))
                            .map((option, i) => (
                                <option
                                    key={option.value}
                                    value={option.value}
                                    date_effective={option.date_effective}
                                    disabled={i === 0}

                                >
                                    {option.label}
                                </option>
                            ))}
                </select>
            </div>
        );
    }

    getPeriodData() {
        Fetch("functions_api.php", {
            action: "pop_selectPeriod",
            obj_id: "selectPeriod",
            rate_id: this.props.rate_id,
        }).then((data) => {
            if (data.status === "ok") {
                // console.log('pop_selectPeriod', data.data)

                let options = []
                options.push({
                    label: "Select a period",
                    value: 0,
                    date_effective: 9999999999999,

                })

                for (let i = 0, l = data.data.length; i < l; i++) {
                    // console.log('option', this.state.dont_show, data.data[i].o_value)
                    // console.log(this.state.dont_show.includes(data.data[i].o_value))
                    if (!this.state.dont_show.includes(data.data[i].o_value)) {
                        options.push({
                            label: data.data[i].o_text,
                            value: data.data[i].o_value,
                            date_effective: Date.parse(data.data[i].date_effective)
                        })
                    }
                }

                // console.log("After fetch: complete options", options, options2)

                //Trigger render
                this.setState({period_options: options})

                //set default value if length equal 1
                // if (data.data.length === 1) {
                //     const label = options[0].o_text;
                //     const value = options[0].value;
                //     sessionStorage.setItem("prev_period_label", label);
                //     sessionStorage.setItem("prev_reid_select", value);
                //     this.setState({selectedPeriod: {label, value}})
                // }
            } else {
                console.log("Fetch ERROR", data);
            }
        });
    }
}

export default PeriodSelect;
