import React, {PureComponent} from "react";
import {Fetch} from "../../helpers/Fetch";

import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    ResponsiveContainer,
    // Tooltip,
} from "recharts";

// import {svr_name} from "../../helpers/AppConstants";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import BadReadingsTable from "./BadReadingsTable";

dayjs.extend(utc);
dayjs.extend(timezone);

export class BadDataGraph extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            chart_loaded: true,
            loading: false,
            initial_date: dayjs().tz(this.props.site_tz).startOf("day"),
            endDate: dayjs().tz(this.props.site_tz).add(1, "day").startOf("day"),
            rg_tz: this.props.site_tz,
            graph_type: "graph",
            graph_difference: "1", //Span of chart and data
            plot_data: [],
            raw_data: {},
            detect_table_pan: true,
            // selectedSpan: "86400"
        };
    }

    componentDidMount() {
        const {initial_date, endDate, graph_difference} = this.state;
        const {report_bill_id} = this.props;
        this.get_trend_data(
            report_bill_id,
            initial_date,
            endDate,
            graph_difference
        );
    }

    handle_date_change = (e) => {
        this.setState({
            endDate: dayjs(e.target.value).tz(this.props.site_tz, true).add(this.state.graph_difference, "day").startOf('day'),
            initial_date: dayjs(e.target.value).tz(this.props.site_tz, true).startOf('day'),
        });
    };

    /******************************************************
     * Events
     ******************************************************/
    handle_update_dates = () => {
        const {report_bill_id} = this.props;
        const {initial_date, endDate, graph_difference} = this.state;
        this.get_trend_data(
            report_bill_id,
            initial_date,
            endDate,
            graph_difference
        );
    };

    transformArray = (data) => {
        const currentDate = dayjs().tz(this.props.site_tz).format("YYYY-MM-DD")
        const currentHour = dayjs().tz(this.props.site_tz).format("HH:mm:ss")

        return data.map((item) => {
            const itemDate = item.x.split('T'); // Create a Date object from the 'x' property
            const date = itemDate[0]
            const hour = itemDate[1]

            // Check if kwd is 0 and date is >= after my current date/hour
            if (item.kwd === 0 && (date > currentDate || (date === currentDate && hour >= currentHour))) {
                // Set kwd to null
                return {
                    ...item,
                    kwd: null,
                };
            } else {
                // Keep the original kwd value
                return item;
            }
        });
    }
    get_trend_data = (rbid, from_date, to_date, graph_difference) => {
        const {detect_table_pan} = this.state;

        Fetch("bad_data.php", {
            action: "get_trend_data",
            report_bill_id: rbid,
            fromDate: from_date.utc().format("MM-DD-YYYY HH:mm"),
            toDate: to_date.utc().format("MM-DD-YYYY HH:mm")
        })
            .then((fdata) => {
                //Set kwd null when date is after current date/hour
                const data = this.transformArray(fdata.data)

                //Get the max Kwh value
                if (fdata && fdata.status === "ok") {
                    const maxKwd = Math.max.apply(
                        Math,
                        data.map(function (o) {
                            return o.kwd;
                        })
                    );

                    //Set the X axis tickCount
                    let x_tickCount;

                    x_tickCount = 4;

                    //Set the Y range se we get nice round numbers for each tick
                    let rangeYDomain = 0;
                    let y_tickCount; //Number of ticks including zero
                    if (maxKwd > 10000) {
                        //10,000kwh bumps
                        rangeYDomain = (Math.floor(maxKwd / 10000) + 1) * 10000;
                    } else if (maxKwd > 1000) {
                        //1,000kwh bumps
                        rangeYDomain = (Math.floor(maxKwd / 1000) + 1) * 1000;
                    } else if (maxKwd > 100) {
                        //100 bumps
                        rangeYDomain = (Math.floor(maxKwd / 100) + 1) * 100;
                        if (rangeYDomain < 400) y_tickCount = rangeYDomain / 50 + 1;
                        else y_tickCount = rangeYDomain / 100 + 1;
                    } else if (maxKwd > 10) {
                        //10 bumps
                        rangeYDomain = (Math.floor(maxKwd / 10) + 1) * 10;
                        if (rangeYDomain < 40) y_tickCount = rangeYDomain / 5 + 1;
                        else y_tickCount = rangeYDomain / 10 + 1;
                    } else {
                        //1 bumps
                        rangeYDomain = (Math.floor(maxKwd / 1) + 1) * 1;
                        if (rangeYDomain < 4) y_tickCount = rangeYDomain + 1;
                        else y_tickCount = rangeYDomain / 1 + 1;
                    }
                    this.setState({
                        plot_data: data,
                        y_axis_domain: [0, rangeYDomain],
                        x_tickCount: x_tickCount,
                        y_tickCount: y_tickCount,
                        graph_type: "line", //Line chart
                        endDate: to_date, //Right hand side of the plot or from/start date
                        initial_date: from_date, //Left hand side of the plot or to/end date
                        loading: false,
                        graph_difference: graph_difference,
                        detect_table_pan: !detect_table_pan,
                    });
                }

            }).catch((error) => {
            console.error("Error:", error);
        });
    };

    formatXAxisLine = (tickItem) => {
        return dayjs(tickItem).format("MM-DD HH:mm");
    };

    formatYAxis = (tickItem) => {
        return Number(tickItem).toFixed(2);
    };

    panNegative = () => {
        // Go one day back
        const {graph_difference, initial_date} = this.state;
        console.log("panNegative", graph_difference, initial_date)
        const {report_bill_id} = this.props;
        //this.props.detect_table_pan()

        let calculated_initial = dayjs(initial_date)
            .subtract(graph_difference, "day");
        let calculated_end = initial_date;

        this.get_trend_data(
            report_bill_id,
            calculated_initial,
            calculated_end,
            graph_difference
        );
    };

    panPositive = () => {
        // Go one day forward
        const {graph_difference, endDate} = this.state;
        console.log("panPositive", graph_difference, endDate)
        const {report_bill_id} = this.props;
        //this.props.detect_table_pan()

        let calculated_end = dayjs(endDate)
            .add(graph_difference, "day");
        let calculated_initial = endDate;

        this.get_trend_data(
            report_bill_id,
            calculated_initial,
            calculated_end,
            graph_difference
        );


    };

    spanChange = (e) => {
        console.log('spanChange', e.target.value, this.state.graph_difference, this.state.endDate)

        const {report_bill_id} = this.props;
        // const {graph_difference, initial_date} = this.state;
        const {initial_date} = this.state;
        // this.props.detect_table_pan()

        this.get_trend_data(
            report_bill_id,
            dayjs(this.state.endDate)
                .subtract(e.target.value, "days"),  //start date
            initial_date,  //end date
            e.target.value //graph_difference
        );

    }

    render() {
        const {
            chart_loaded,
            loading,
            endDate,
            initial_date,
            rg_tz,
            graph_type,
            plot_data,
            y_axis_domain,
            y_tickCount,
            detect_table_pan,
            graph_difference
        } = this.state;

        // console.log('graph_difference', graph_difference)
        const {device_id, report_bill_id, site_tz} = this.props;
        // const CustomTooltipLine = ({active, payload, label}) => {
        //     console.log(payload, label)
        //     return (
        //         <div className="custom-tooltip">
        //             <div className="custom-tooltip-header">
        //                 <p className="tooltip-label label">{`${dayjs(label).utcOffset(site_tz).format(
        //                     "MM-DD HH:mm"
        //                 )}`}</p>
        //             </div>
        //             <div style={{color: "blue", float: "left"}}>&#9632;</div>
        //             <div className="custom-tooltip-body" style={{float: "left"}}>
        //                 {" "}
        //                 kW Demand: {payload[0] && payload[0].value}
        //             </div>
        //         </div>
        //     );
        // };

        return (
            <div>

                {/************* Line chart *************/}
                {graph_type === "line" && ( //Show line chart
                    <ResponsiveContainer
                        width="100%"
                        height={400}
                        className="Tenantv2_chart mb-5"
                    >
                        <LineChart
                            width="130%"
                            height={700}
                            data={plot_data}
                            margin={{top: 0, right: 20, bottom: 25, left: 5}}
                        >
                            <Line
                                dataKey="kwd"
                                stroke="#8884d8"
                                dot={false} //No data marker
                                type="linear"
                                animationDuration={0}
                                isAnimationActive={false}
                                connectNulls={true}
                            />
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc"/>

                            <XAxis //category
                                dataKey="x" //Used for plotting
                                tickFormatter={this.formatXAxisLine} //Format the timestamps
                                angle={70}
                                style={{fontSize: ".8rem"}}
                                dy={33} //padding from y-axis
                                dx={15} //padding from x-axis
                                label={{
                                    value: "kW Demand",
                                    dy: 80,
                                    angle: 0,
                                    position: "center",
                                }}
                            />
                            <YAxis
                                tickFormatter={this.formatYAxis}
                                type="number"
                                domain={y_axis_domain}
                                tickCount={y_tickCount}
                                label={{
                                    value: "kW Demand",
                                    dx: -40, //padding from x-axis
                                    angle: -90,
                                    position: "center",
                                }}
                            />
                            {/*<Tooltip content={<CustomTooltipLine/>}/>*/}
                        </LineChart>
                    </ResponsiveContainer>
                )}

                {chart_loaded ? (
                    <div>
                        <div className="input-group d-sm-none d-md-block text-center">
                            <div className="offset-4 col-4">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="radioSpanSelection"
                                           id="spanSelect24hours" value="1"
                                           onChange={this.spanChange} checked={graph_difference === '1'}/>
                                    <label className="form-check-label" htmlFor="spanSelect24hours">24
                                        Hours</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="radioSpanSelection"
                                           id="spanSelect7days" value="7"
                                           onChange={this.spanChange} checked={graph_difference === '7'}/>
                                    <label className="form-check-label" htmlFor="spanSelect7days">7 Days</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="radioSpanSelection"
                                           id="spanSelect30days" value="30"
                                           onChange={this.spanChange} checked={graph_difference === '30'}/>
                                    <label className="form-check-label" htmlFor="spanSelect30days">30
                                        Days</label>
                                </div>
                            </div>
                            <div className="mx-auto">
                                <button
                                    id="PanBackward"
                                    className={` hidden-print ${
                                        loading ? "" : "btn-primary"
                                    } `}
                                    value="negative"
                                    onClick={this.panNegative}
                                    disabled={loading}
                                >
                      <span
                          className="fas fa-chevron-left"
                      />
                                </button>

                                <input
                                    className="rounded text-center mx-2 "
                                    id="timezone"
                                    value={rg_tz}
                                    disabled
                                />

                                <label
                                    htmlFor="fromDate"
                                    className="col-form-label form-control-sm"
                                >
                                    From:
                                </label>
                                <input
                                    className="rounded text-center"
                                    type="datetime-local"
                                    id="fromDate"
                                    step={1}
                                    value={dayjs(initial_date).startOf('day').format("YYYY-MM-DD HH:mm")}
                                    onChange={this.handle_date_change}
                                />

                                <label
                                    htmlFor="toDate"
                                    className="col-form-label form-control-sm"
                                >
                                    To:
                                </label>
                                <input
                                    className="rounded text-center mx-2 "
                                    id="toDate"
                                    type="datetime-local"
                                    value={dayjs(endDate).startOf('day').format("YYYY-MM-DD HH:mm")}
                                    disabled
                                />

                                <button
                                    id="PanForward"
                                    className={`${
                                        loading ? "" : "btn-primary"
                                    }  hidden-print`}
                                    value="positive"
                                    disabled={loading}
                                    onClick={this.panPositive}
                                >
                      <span
                          className="fas fa-chevron-right"
                      />
                                </button>

                                <button
                                    className="btn btn-primary btn-sm mb-1 mx-2"
                                    onClick={this.handle_update_dates}
                                >
                                    Update
                                </button>
                            </div>
                        </div>

                        {loading && (
                            <div
                                className="text-center"
                                style={{fontSize: "15px", marginTop: "10px"}}
                            >
                                <div className="loading-div">
                                    Loading{" "}
                                    <img
                                        alt=""
                                        height={20}
                                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        <div
                            className="text-center"
                            style={{height: "200px", fontSize: "20px"}}
                        >
                            <div className="loading-div">
                                Loading{" "}
                                <img
                                    alt=""
                                    height={20}
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                />
                            </div>
                        </div>
                    </>
                )}
                <BadReadingsTable
                    device_id={device_id}
                    site_tz={site_tz}
                    report_bill_id={report_bill_id}
                    detect_table_pan={detect_table_pan}
                    initial_date={initial_date}
                    endDate={endDate}
                />
            </div>
        );
    }
}
