import React, {Component} from "react";
// import SiteSelect from "../Global/Site_select2";
import Select from "react-select";
import { Fetch } from "../../helpers/Fetch";
import AdminReportTable from "./AdminReportTable";
import AssignedReportsTable from "./AssignedReportsTable";

class AdminReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report_groups: [],
      reports: [],
      site_id: sessionStorage.getItem("prev_site_id") || 0,
      site_data: [],
      site_selected: false,
      show_report_table: false,
      report_data:[],
      show_assigned:false
    };
    this.user_sites_ref = React.createRef();
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    //If the props change because of a change of the main edited_user_id in Admin2.js,
    if (this.props.edited_user_id !== prevProps.edited_user_id) {
      this.setState({
        site_selected: false,
        show_report_table: false,
      });
      this.get_user_sites();
    }
  }

  componentDidMount() {
    this.get_user_sites();
    this.get_assigned_reports()
    let site_id = sessionStorage.getItem("prev_site_id");
    if (site_id) {
      this.get_report_groups(site_id);
    }
  }

  get_assigned_reports = () => {
    Fetch("admin_reports.php", {
      action: "get_assigned_reports",
      user_id: this.props.edited_user_id,
    }).then((data) => {
      console.log(data)
      this.setState({
        report_data: data.data,
      });
    });
  };

  get_user_sites = () => {
    Fetch("admin_reports.php", {
      action: "get_user_site_list",
      user_id: this.props.edited_user_id,
    }).then((data) => {
      this.setState({
        site_data: data.data,
      });
    });
  };

  get_report_groups = (site_id) => {
    Fetch("admin_reports.php", {
      action: "get_site_report_groups",
      site_id: site_id
    })
      .then((data) => {
        if (data.status === "ok") {
          this.setState({
            report_groups: data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  };
  handleSiteChange = (e) => {
    // console.log("site change handled", e.val)
    this.setState(
      {
        site_tz: e.site_tz,
        site_id: e.val,
        site_selected: true,
        show_report_table: false,
        show_assigned:false
      },
      () => this.get_report_groups(e.val)
    );
    this.user_sites_ref["current"]
      ? this.user_sites_ref.current.select.clearValue()
      : console.log("clear");
  };

  handleReportGroupChange = (e) => {
    console.log(e);
    if (e && e.val) {
      Fetch("admin_reports.php", {
        action: "get_site_reports",
        report_group_id: e.val,
        edited_user_id: this.props.edited_user_id,
      })
        .then((data) => {
          if (data.status === "ok") {
            this.setState({
              reports: data.data.sort((a, b) =>
                a.report_name.localeCompare(b.report_name)
              ),
              show_report_table: true,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  showAssigned=()=>{
    const {show_assigned} = this.state
    this.setState({
      show_assigned:!show_assigned
    })
  }

  render() {
    const {
      report_groups,
      reports,
      site_id,
      site_data,
      site_selected,
      show_report_table,
      report_data,
      show_assigned
    } = this.state;
    const { edited_user_id } = this.props;
    console.count("Reports render", site_id);


    const site_options = site_data
      .sort((a, b) => a.site_name && a.site_name.localeCompare(b.site_name))
      .map((val) => ({
        val: val.site_id,
        label: val.site_name,
      }));

    const report_group_data = report_groups
      .sort(
        (a, b) =>
          a.report_group_name &&
          a.report_group_name.localeCompare(b.report_group_name)
      )
      .map((val) => ({
        val: val.report_group_id,
        label: val.report_group_name,
      }));

    const defaultSortBy = [
      {id: "Apartment", desc: false}
    ]  //Default sort - use column names
    const hiddenColumns = ["report_bill_id"]  //No columns hidden in display

    return (
      <div>
        {show_assigned &&
          <div>
            {report_data.length > 0 &&<h5 className="text-center">Assigned Reports</h5>}
            {
                report_data.length > 0 && (
                    <div className="table-responsive overflow-auto">
                      <AssignedReportsTable
                          data={report_data}
                          defaultSortBy={defaultSortBy}
                          hiddenColumns={hiddenColumns}
                      />
                    </div>
                )
            }
          </div>
        }
        <div className=" col-4 offset-4">
          <button className="btn btn-sm btn-primary me-3 mb-1" onClick={this.showAssigned}>{show_assigned ?"Hide":"Show"} Assigned Reports</button>
          <label>
            Select a site:(<small>Filtered using assigned sites</small>)
          </label>
          <Select
            options={site_options}
            className="mb-3"
            onChange={this.handleSiteChange}
          />
          {site_selected && (
            <>
              <label>Select a report group:</label>
              <Select
                options={report_group_data}
                ref={this.user_sites_ref}
                onChange={this.handleReportGroupChange}
              />
            </>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="mt-3">

            {show_report_table && reports.length > 0 && (
              <AdminReportTable
                reports={reports}
                site_id={site_id}
                edited_user_id={edited_user_id}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AdminReports;
