import React from "react";
import {Link} from "react-router-dom";
import {svr_name} from "../helpers/AppConstants";
import {history} from "../helpers/history";
import {Alert} from "react-bootstrap";
import SimpleReactValidator from 'simple-react-validator';

export default class Register extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {
                org_name: '',
                fname: '',
                lname: '',
                email: '',
                phone_main: '',
                username: '',
                password: '',
                confirmPassword: ''
            },
            alert:'',
            signing_up:false
        }
        this.validator = new SimpleReactValidator();
    }

    onFormItemChanged = (e) => {
        const {name, value} = e.target;
        const {form} = this.state;
        this.setState({
            form: {
                ...form,
                [name]: value
            }
        })
    }
    userRegister = (e) => {
        e.preventDefault()
        const {form} = this.state
        // console.log(form)
        this.setState({
            signing_up:true
        })
        if(this.validator.allValid()){
            if(form.password === form.confirmPassword){
                fetch(svr_name + 'register.php', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(form),
                })
                    .then(response => response.json())
                    .then(data => {
                        if(data.registrationStatus === "good" ){
                            history.push("/login");
                            alert("Registration successful. You can't log in immediately. EnergyChaser will be in touch shortly")
                        } else {
                            this.setState({
                                signing_up:false
                            })
                        }

                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            } else{
                this.setState({
                    alert:"password and confirm password should match",
                    signing_up:false
                })
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }


    }

    render() {
        const {form, alert} = this.state;
        return (
            <div className="signin-container">
                <div className="signin-header">
                    <img
                        src="https://data.energychaser.com/img/ec_logo.gif"
                        className="landing-logo"
                        alt="logo"
                    />
                </div>
                <form onSubmit={this.userRegister} className="signin-form">
                    {
                        alert && <Alert className="text-center" variant="danger">{alert}</Alert>
                    }
                    <h3 className="form-header">Sign up to an EnergyChaser Account</h3>
                    <div className="form-header-description">
                        Already have an account?{" "}
                        <Link to={'/'}>Sign in</Link>
                    </div>
                    <label className="form-label">Company or Landlord Name</label>
                    <input
                        type="text"
                        placeholder="e.g YZX ltd."
                        className="form-control"
                        value={form.org_name}
                        name="org_name"
                        onChange={(event) =>
                            this.onFormItemChanged(event)
                        }
                    />
                    {this.validator.message('Company', this.state.form.org_name, 'required')}
                    <label className="form-label">First Name</label>
                    <input
                        type="text"
                        placeholder="e.g john"
                        className="form-control"
                        value={form.fname}
                        name="fname"
                        onChange={(event) =>
                            this.onFormItemChanged(event)
                        }
                    />
                    {this.validator.message('first Name', this.state.form.fname, 'required')}
                    <label className="form-label">Last Name</label>
                    <input
                        type="text"
                        placeholder="e.g doe"
                        className="form-control"
                        value={form.lname}
                        name="lname"
                        onChange={(event) =>
                            this.onFormItemChanged(event)
                        }
                    />
                    {this.validator.message('last name', this.state.form.lname, 'required')}
                    <label className="form-label">Email</label>
                    <input
                        type="email"
                        placeholder="e.g johndoe@xyz.com"
                        className="form-control"
                        value={form.email}
                        name="email"
                        onChange={(event) =>
                            this.onFormItemChanged(event)
                        }
                        required
                    />
                    {this.validator.message('email', this.state.form.email, 'required|email')}
                    <label className="form-label">phone</label>
                    <input
                        type="phone"
                        placeholder="e.g +179211222"
                        className="form-control"
                        value={form.phone_main}
                        name="phone_main"
                        onChange={(event) =>
                            this.onFormItemChanged(event)
                        }
                        required
                    />
                    {this.validator.message('phone_main', this.state.form.phone_main, 'required')}
                    <label className="form-label">Username</label>
                    <input
                        type="text"
                        placeholder="e.g jdoe"
                        className="form-control"
                        value={form.username}
                        name="username"
                        onChange={(event) =>
                            this.onFormItemChanged(event)
                        }
                        required
                    />
                    {this.validator.message('username', this.state.form.username, 'required')}
                    <label className="form-label">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        value={form.password}
                        name="password"
                        onChange={(event) =>
                            this.onFormItemChanged(event)
                        }
                    />
                    {this.validator.message('password', this.state.form.password, 'required')}
                    <label className="form-label">Confirm Password</label>
                    <input
                        type="password"
                        className="form-control"
                        value={form.confirmPassword}
                        name="confirmPassword"
                        onChange={(event) =>
                            this.onFormItemChanged(event)
                        }
                    />
                    {this.validator.message('confirm password', this.state.form.confirmPassword, 'required')}
                    <button
                        className="btn btn-primary login-btn"
                        onClick={this.userRegister}
                        disabled={this.state.signing_in}
                        style={{ marginTop: 20 }}
                    >
                        Register
                    </button>
                    <div className="signin-form-footer">
                        <div>We may contact you by phone to confirm your identity. We may also contact your
                            landlord/management company to confirm and determine access levels.
                        </div>
                    </div>
                </form>
            </div>

        )
    }
}
