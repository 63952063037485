import { Component } from "react";
import Select from "react-select";
import { Fetch } from "../../helpers/Fetch";

class GroupSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterText: "",
      group_data_options:[],
      group_data:[],
      report_group_id:"",
      report_group_name:"",
      select_disabled:false

    };
  }
  componentDidMount() {
    this.getGroupData(); //Get from server
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (prevProps.site_id !== this.props.site_id ) {
     this.getGroupData()
    }
  }

  getGroupData() {
    Fetch("functions_api.php", {
      action: "fill_groups_dropdown",
      site_id: this.props.site_id,
    }).then((data) => {
      if (data.status === "ok") {
         if (data.data.length === 1) {
             sessionStorage.setItem("prev_group_id", data.data[0].report_group_id);
             sessionStorage.setItem("prev_group_name", data.data[0].report_group_name);
          this.setState(
              {
                group_data: data.data,
                report_group_id: data.data[0].report_group_id,
                report_group_name: data.data[0].report_group_name,
                select_disabled:true
              }
          );
        } else{
           this.setState({group_data:data.data})
         }
        this.setState({});
      } else {
        console.log("Fetch ERROR", data);
      }
    });
  }

  render() {
    const {group_data} = this.state
    const group_data_options = group_data.map((val) => ({
        label: val.report_group_name,
        value: val.report_group_id,
      }));

    const prev_group_id = sessionStorage.getItem("prev_group_id");
    const prev_group_name = sessionStorage.getItem("prev_group_name");
    return (
      <Select
        options={group_data_options}
        className="mt-2 mb-2"
        //Use the change script from the caller page
        onChange={this.props.handleGroupSelect}
        placeholder="Select a Site"
        // isDi={select_disabled}
        value={{
          label:
              group_data.length === 1
                  ? prev_group_name
                  : prev_group_name,
          value:
              group_data.length === 1
                  ? prev_group_id
                  : prev_group_name,
        }}
        isDisabled={group_data_options.length <= 1}
      />
    );
  }


}

export default GroupSelect;
